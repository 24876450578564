import React, { Component } from "react";
import Modal from "../Modal/Modal";
import "./Product.css";

class Product extends Component {
  state = {
    showModal: false,
    dataModal: {
      name: "",
	  name2: undefined,
	  w:"",
	  h:"",
	  stl:{},
	  mw:"",
	  mh:"",
	  mstl:{}

    }
  };

  getModal = data => {
    this.setState({ showModal: true, dataModal: data });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <div className="container"  style={{clear:'both'}}>

        {!this.state.showModal &&
			this.props.data.map((data, key) => (
          <div key={key} class="clear">
			<img				
				src={data.name}
				height={data.h}
				width={data.w}
				style={data.stl}
				onClick={() => this.getModal(data)}
				alt="no image"
           />
          </div>

        ))}

        <Modal
          show={this.state.showModal}
          onHide={this.hideModal}
          name={this.state.dataModal.name2}
		  w={this.state.dataModal.mw}
		  h={this.state.dataModal.mh}
		  stl={this.state.dataModal.mstl}
        />
      </div>
    );
  }
}
export default Product;

