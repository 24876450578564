import React from "react";
import props from 'prop-types';
import Hashs from "../components/Hashs";
import { Main } from "../components/styles/Main";

export default function Hashxy(props) {
  const funcNav=props.funcNav;
  return (
    <>
	   <Hashs funcNav={funcNav} />
	 
    </>
  );
}
