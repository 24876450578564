import { Block } from "./block";
import { SHA256 } from "crypto-js";

export class Blockchain {
  constructor() {
    this.chain = [this.initGenesisBlock()];
    this.pendingTransactions = [];
    this.miningReward = 100;
    this.difficulty = 80;
	this.tmpNonce = 0;
	this.tmpPoW=true;
	this.tStamp=0;
  }
  pow = function(hsh) {
	this.tmpPoW = ((parseInt(hsh)%10 > 3 ));
	return this.tmpPoW;
  }
  
  stmpNonce = function(stmpnonce) {
	this.tmpNonce = stmpnonce;
	return this.tmpNonce;
  }

  quersumme = function(forceOneDigit) {
    var z = forceOneDigit.toString().split('');
    for (var i=0, quer=0; i < z.length; quer+=z[i++]-0);
    return quer%100;
}

  initGenesisBlock() {
    let prevHash =
      "0";
    let data =[
	 {"from":"9","to":"3","amount":"10","prfnr":13,"sig":"73"},
	 {"from":"9","to":"7","amount":"10","prfnr":17,"sig":"97"},
     {"from":"9","to":"11","amount":"10","prfnr":13,"sig":"73"},
     {"from":"9","to":"13","amount":"10","prfnr":17,"sig":"97"},
     {"from":"9","to":"19","amount":"10","prfnr":21,"sig":"81"},	
	]; 
//"Initial Block in the Chain";
    let firstBlock = new Block(
      prevHash,
      0,
      JSON.stringify(data),
    );
    firstBlock.nonce = 0; 
	firstBlock.data= JSON.stringify(data);
	firstBlock.transactions = data;
	firstBlock.tS = "Thu Oct 06 2022 14:58:33 GMT+0200 (Mitteleuropäische Sommerzeit)";
	firstBlock.hash=this.calculateHash(firstBlock,0);
    return firstBlock;
  }

  calculateHash(block, testNonce) {
  if(block.data.toString().startsWith('DE')) {
	let pz=98-this.isValidIBANNumber(block.data);
	//console.log(pz);
	if (block.data.toString().length !== 22) {
		pz = this.quersumme(parseInt(block.data.toString().replace(/[^0-9]/g,''), 10));
	}
	return pz;
  }
  else {
    let pzh=0, tst=block.data.split(",{");
	//console.log("block.data ",block.data);
    let lenbd=tst.length;
	if (lenbd>0) {
		for (let i=0; i<lenbd;i++) {
			pzh+=this.quersumme(tst[i].replace(/[^0-9]/g,''));
			//if (tst[i].length>0) {console.log("i,tst",tst); console.log("  pzh",pzh);} 
		}
	}
	if (block.tS) {
		pzh += this.quersumme(block.tS.toString().replace(/[^0-9]/g,''));
	}
    pzh += this.quersumme(parseInt((testNonce +  block.index + block.prevHash).
	                                                toString().replace(/[^0-9]/g,''), 10)); 
	let	pz=(2*(pzh%10===5)+ (pzh%2===0)+pzh)%100;
	//console.log(testNonce,pzh,pz);
    return pz;
   }
 }

  validateHash = (block) => {
    if ( block.data.toString().startsWith('DE') ) { return (1 === 1);}
    else
    return  this.pow(this.calculateHash(block, block.nonce));
    
  };

  updateChain(block) {
    // update hash for each block starting at this block and looping through the rest of the chain + validate new hashes
    for (let i = block.index; i < this.chain.length; i++) {
      if (i !== 0) { 
	   this.chain[i].prevHash = this.chain[i - 1].hash;
      }
	  this.chain[i].hash = this.calculateHash(
        this.chain[i],
        this.chain[i].nonce);
		
	  this.chain[i].error = false;
      
      if (i+1 === this.chain.lengthth){
		  this.chain[i].error = !this.validateHash(this.chain[i]);
	  }
    }
  }

  latestBlock() {
    return this.chain[this.chain.length - 1];
  }

  mineBlock(block, isChain) {
    let testNonce = this.tmpNonce;
    //  while ( (parseInt(this.calculateHash(block,testNonce))>this.difficulty) && testNonce < 9999
	//  ) {
    //    testNonce=testNonce+; 
	//	if (testNonce % 1000 === 0 ){
	//		block.tS =  new Date( Date.now() )
	//	}
	// }
      this.chain[block.index].tS = block.tS;
      this.chain[block.index].nonce = testNonce;
      this.chain[block.index].hash = this.calculateHash(block, testNonce);
	  this.chain[block.index].error = !this.validateHash(block);
	  this.tmpNonce=0;

 //weli 2023.02.12     if (isChain) {
        this.updateChain(block);
 //weli 2023.02.12     }
      return this.chain;

  }

  addNewBlock(data, prevHash) {
	if (data.Transactions === []) return this.chain;
    // calculate initial values for new block and instantiate it
    if (!prevHash) prevHash = this.chain[this.chain.length - 1].hash;
    let index = this.chain.length;
    let newBlock = new Block(prevHash, index, data);
    newBlock.nonce=this.tmpNonce;
    this.chain.push(newBlock);

    this.mineBlock(this.chain[index], false);
    this.chain[index].hash = this.calculateHash(newBlock, newBlock.nonce);

    return this.chain;
  }
 
	addNewComputedBlock(data, prevHash,tS=0,nonce=0) {
    // calculate initial values for new block and instantiate it
    if (!prevHash) prevHash = this.chain[this.chain.length - 1].hash;
    let index = this.chain.length;
    let newBlock = new Block(prevHash, index, JSON.stringify(data));
    newBlock.transactions = data;
	newBlock.tS=tS;
	newBlock.nonce=nonce; //weli 0223 war auskommentiert
    newBlock.nonce=this.tmpNonce;
	//newBlock.error=this.pow(this.calculateHash(newBlock, block.nonce));
    // push block to chain
    this.chain.push(newBlock);

    this.mineBlock(this.chain[index], false);
    this.chain[index].hash = this.calculateHash(newBlock, newBlock.nonce);
	this.chain[index].error= this.pow(this.calculateHash(newBlock, newBlock.nonce));
		console.log(this.tmpPow,this.chain[index].error);
    return this.chain;
  }

  updateBlockData(blockIndex, data) {
    this.chain[blockIndex].data = data;
	if (Array.isArray(data)) {
    	this.chain[blockIndex].transactions=data;
	}
    this.chain[blockIndex].hash = this.calculateHash(
      this.chain[blockIndex],
      this.chain[blockIndex].nonce
    );
    this.chain[blockIndex].error = !this.validateHash(this.chain[blockIndex]);
    this.updateChain(this.chain[blockIndex]);

    return this.chain;
  }

  updateBlockNonce(blockIndex, nonce) {
    this.chain[blockIndex].nonce = nonce;
    this.chain[blockIndex].hash = this.calculateHash(
      this.chain[blockIndex],
      nonce
    );
    this.chain[blockIndex].error = !this.validateHash(this.chain[blockIndex]);
    this.updateChain(this.chain[blockIndex]);

    return this.chain;
  }

  updateBlockTransactions(blockIndex, transactionIndex, key, value) {
    this.chain[blockIndex].transactions[transactionIndex][key] = value;
    this.chain[blockIndex].data = JSON.stringify(
      this.chain[blockIndex].transactions
    );

    this.updateChain(this.chain[blockIndex]);

    return this.chain;
  }


 mod97(string) {
        
        var checksum = string.slice(0, 2), fragment;
        for (var offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }


 mod97full(string) {
        
        var checksum = parseInt(string.replace(/[^0-9]/g,''), 10) % 97;
        
        return checksum;
    }


isValidIBANNumber(input) {
        var CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
        };
        var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''),
                code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
                digits;
        // check syntax and length
        //if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        //    return false;
        //}
        // rearrange country code and check digits, and convert chars to ints
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
                                                                  return letter.charCodeAt(0) - 55;
                                                                 }
                 );
        // final check
        return this.mod97(digits.substring(0,digits.length-2)+"00");
    }

}
