import React from "react";
import ECDSA from "../components/ECDSA";
import { Main } from "../components/styles/Main";
import props from 'prop-types';

export default function ECDSA7(props) {
	props.funcNav(false);
  return (
    <>
	<Main>
    <ECDSA/>
	</Main>
    </>
  );
}
