import React from "react";
//opt import { Link } from "react-router-dom";
//opt import props from 'prop-types';
//opt import Block from "../components/Block";
import { Main } from "../components/styles/Main";
//import Product	from "../components/Product/Product";
import auftrag0 from "../bilder/auftrag0.png";
// optimport Back     from "../bilder/back.png";


export default function EggBsp2(props) { 
//  const funcNav=props.funcNav;
//  	 props.funcNav(false);

  return ( 
     <>
      <Main>
        <h1>Egg-Signatur</h1>
        <hr />
<p>
<img height="75%" width="75%" src={auftrag0} style={{float:"left","padding-right" : 20}} alt='auftrag0' />
Zur Ver- und Entschlüsselung der Prüfnummern verwenden wir bei unseren EggCoins das folgende Verfahren<br/>(Abbildung als Beispiel)<br/><br/>

<ul style={{"padding-left" : 1}}>
<li> Der <b>öffentliche Schlüssel</b> jedes Kontos ist die <b>Kontonummer</b>, im Beispiel 3</li>
<li> Der <b>private Schlüssel</b> von <b>Konto 3  ist 7 prv(3)=7 </b> </li>

<li> Die <b>Prüfzahl P-Nr = 9</b> der Beispiel-Transaktion von Konto 3 wird verschlüsselt, indem sie mit dem 
<b> geheimen Schlüssel 7 potenziert</b> wird.<br/><br/>
 <i>dabei wird dabei immer nur mit den zwei rechten Ziffern weitergerechnet<br/>
 und mit &otimes; sei die Multiplikation modulo 100 bezeichnet</i><br/><br/> 
<b>Sig=P-Nr <sup>prv(3)</sup> |100 (modulo 100)</b><br/>
<b>Sig=9 <sup> 7 </sup>|100 (modulo 100)</b><br/>
<b>=9&otimes;9&otimes;9&otimes;9&otimes;9&otimes;9&otimes;9  </b><br/>
<b>=81&otimes;9</b>&otimes;9&otimes;9&otimes;9&otimes;9  <br/>
<b>=29&otimes;9</b>&otimes;9&otimes;9&otimes;9 , da <b>81*9</b> = 7<u><b>29</b></u> aber nur die letzen beiden Ziffern zählen<br/>
<b>=61&otimes;9</b>&otimes;9&otimes;9, da <b>29*9</b> = 2<u><b>61</b></u> aber nur die beiden letzten Ziffern zählen<br/>
<b>=49&otimes;9</b>&otimes;9, zwei letzte Ziffern von <b>61*9</b>=5<u><b>49</b></u><br/>
<b>=41&otimes;9</b>, zwei letzte Ziffern von <b>49*9</b>=4<u><b>41</b></u><br/>
<u><b>=69</b></u>, zwei letzte Ziffern von <b>41*9</b>=3<u><b>69</b></u> <br/><br/>
</li>
<li>Die Entschlüsselung der Signatur erfolgt nach dem gleichen Verfahren, wie die Verschlüsselung aber mit 
es wird mit dem öffentlichen Schlüssen potenziert, der jedem zugänglich ist, wie der Name sagt.</li><br/>
<li><b>Public Key</b> von <b>Konto 3</b> ist <b>3</b>: <br/>
Sig&otimes;Sig&otimes;Sig = 69&otimes;69&otimes;69 = 61&otimes;69 , da 69*69=47<u><b>61</b></u> geht es mit 61 weiter<br/> 
61&otimes;69=<u><b>9</b></u>, da <b>61*69</b> = 42<u><b>09</b></u></li>
</ul> 
</p>
<hr/>
<div><i><b><small>Der folgende Link führt zu meinem Video des noch einfacheren Modulo 10 Public-Key Verfahrens</small></b></i></div>
<a href="https://www.youtube.com/watch?v=xHprz5QzvnM" target="_blank" rel="noreferrer">10-Finger Arithmetik zur Public-Key Verschlüsselung</a>	<br/><br/>	
Den im Video erwähnten Krypto - Taschenrechner finden Sie auf meiner Home-Page unter dem Link<br/>
<a href="https://linslernet.de/crypt.php" target="_blank" rel="noreferrer">Krypto-Taschenrechner</a>
	<br/><br/>	
 

	</Main>

    </>
  );
}
