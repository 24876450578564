import React from "react";
//opt import props from 'prop-types'; 
import {  Link } from "react-router-dom";
import { Main } from "../components/styles/Main";
//opt import Lnk		from "../components/Lnk";
//opt import Product from "../components/Product/Product";

import Back from "../bilder/back.png";




import netzv   from "../bilder/netzv.png"; 

//import ImageComponent from "../components/ImageComponent";
export default function CryptoFiat(props) {
//  const funcNav=props.funcNav;
//  props.funcNav(false);
 
	//console.log(window.location);
	return (

    <>
      <Main>

        <h1>Crypto vs. Fiat Währungen</h1>
		
		<h2>
		"Crypto"-Währungen (Bitcoin & Co) vs. "Fiat"-Geld (Euro, Dollar, Francs,...)
		</h2>
	
		<p>
		Seit der Einführung der Kryptowährung <b>Bitcoin</b> im Jahr 2009 sind zwischenzeitlich über 10.000
		weitere vergleichbare elektronische Zahlungsmittel im Internet entstanden und zum Teil auch wieder verschwunden. 
		(<a href="https://de.wikipedia.org/wiki/Liste_von_Kryptow%C3%A4hrungen" target="_blank"  rel="noreferrer" >
		Liste_von_Kryptow&auml;hrungen - Wikipedia</a>).
		</p>
		<p>
		Ebenso wie Euro, Dollar und alle anderen <b>Fiat-Währungen</b> werden auch <b>Crypto-Coins</b> von Banken verwaltet, die allerdings keine 
		Mitarbeiterinnen und Mitarbeiter haben sondern Server im Internet mit ganz spezieller Software sind. 
		Sie koordinieren und speichern alle Konten und Zahlungsbewegungen. Allerdings unterscheiden sich die "Zweigstellen" 
		von Bitcoin und Co., die <b>"Nodes"</b> genannt werden, an bestimmten Stellen sehr von Euro, Dollar und Co:
		<ul>
		<li><b>Alle Konten</b> einer Crypto-Währung werden durch <b>frei zugängliche Server</b> im Internet verwaltet, die untereinander alle 
			Informationen über alle Konten austauschen. Diese Server werden <b>Nodes</b> genannt.</li><br/>
		<li><b>Jeder</b> kann auf seinem eigenen Rechner mit Internet-Anschluss die frei zugängliche "Node-Software" laden und 
		ebenfalls einen solchen Node betreiben, ohne dass irgendjemand zustimmen muss. Also zu einer "Zweigstelle" werden. </li><br/>
		<li>Es gibt <b>keine zentrale Bank </b> über ihnen. Alle Nodes sind gleichberechtigte Zweigstellen</li><br/>
		<li> Jeder mit Zugang zum Internet kann sich <b>eigenständig Konten</b> in einer der elektronischen Währung
		<b> anlegen, ohne</b> dass eine externe Stelle eine <b>Genehmigung</b> dazu erteilen muss oder verweigern kann.
		</li><br/>
		<li>Zur Kontoanlage werden nur eine <b>neue</b> (noch nicht vergebene) <b>Kontonummer</b> sowie einen 
		<b> geheimen</b> und einen <b>öffentlichen Schlüssel</b> (bestehend aus großen Zahlen oder Zahlenpaare) zum Signieren der <b>Überweisungen</b> (Transaktionen)
		benötigt. <br/>
		</li><br/>
		
		<li>Für die <b>Einrichtung</b> eines Kontos <b>sowie</b> für die Durchführung von <b>Überweisungen</b> ("Transaktionen"
		 genannt) verwendet man <b>"Wallets"</b> genannte Apps, die man sich kostenlos im Internet herunterladen kann. 
		 Bei der Kontoneuanlage sucht die Wallet-App eine freie Kontonummer und erstellt den öffentlichen und den privaten Schlüssel. 
		 (vergleichbar Pin oder Passwort) 
		</li><br/>	
		
		<li> Bereits auf das eigene Konto gesendete Coins können an andere Konten gesendet werden. <b>Alle 
		Kontobewegungen können von jedem mit frei verfügbaren, kostenlosen Programmen</b> (Explorer) auf Desktop-Rechner, Tablets
		oder Smartphones eingesehen werden. 
		</li><br/>

		<li> <b>Alle</b> in einem bestimmten Zeitabschnitt durchgeführten <b>Überweisungen ("Transaktionen")</b>, werden von speziellen Nodes,
		die <b>Miner</b> genannt werden, zu	<b> einer Art Kontoauszug ("Block")</b> zusammengefasst und an alle über Internet erreichbaren Nodes versendet und
		dort gespeichert.
		</li><br/>
		
		<li>Dabei enthält der durch die <b>Miner</b> erstellte Kontoauszug <b>(Block)</b> alle seit dem vorhergehenden Auszug (Block) 
		verarbeiteten Transaktionen. 
		(Im Unterschied zu den bekannten Bankauszügen gibt es in den Blöcken auch Transaktionen unterschiedlicher Accounts)</li><br/>
		
		<li> <b>Jedem</b> neuen Kontoauszug (<b>"Block"</b>) wird abhängig von seinen Inhalten eine unverwechselbare Zahl zugeordnet.
		Sie wird <b> "Hash" </b> genannt und sie bekommt schon bei minimalen Abweichungen im Konto-Auszug (Block) unvorhersehbare neue Werte .
		("Hash engl. Durcheinander") D.h. nachträglich geänderte oder verfälschte Blöcke haben ein Hash, das sich von dem unverfälschten Block 
		 auf der überwiegenden Mehrheit der anderen Nodes unterscheidet und entdeckt wird. 	</li><br/>
		<li>Da das Mining mit neu geschaffen Coins belohnt wird, sind viele Miner gleichzeitig im Wettbewerb um den neuen Block zu erstellen.
		Es wird aber nur 
		ein Node belohnt:<br/><br/>
		durch verändern einer frei wählbaren, <b>Nonce</b> genannten Zahl, können Miner versuchen als erster einen Hash für den neu zu erstellenden Block 
		zu finden, der <b>kleiner</b> als eine <b> vorgegeben Zahl </b> ist. Dieser Node erhält dann vom System automatisch die Belohnung.<br/>
		<ol>
		<i>Die durch Algorithmen vorgegeben Zahl, die der Hash unterbieten muss, ist z.B. bei Bitcoin so gewählt, dass die weltweit zum Mining 
		eingesetzten Rechnen bei der Suche ihrer Nonce-Zahl im Mittel 15 Minuten benötigen. Für diese Rechenleistung wird sehr viel Strom
		verbraucht. Gleichzeitig geschieht dies gleichzeitig von sehr vielen Minern weltweit. <br/>
		Dieser als <b>"Prove of Work (PoW)" </b> bezeichnete Anreiz zum Mining steht damit in einem <b>eklatanten Widerspruch </b>
		zu 	unseren <b>Klimazielen</b> und wird bei neueren Crypto Coins durch andere Verfahren mit sehr viel geringerem Energieverbrauch (z.B. <b>Prove of Stake</b>) ersetzt.</i>
		</ol>
		</li><br/>
		<li>Schließlich werden die <b>Blöcke</b> über diese Hash-Kennzahlen miteinander "verkettet", <b>(Blockchain) </b>indem der <b>Hash 
		des unmittelbar vorhergehenden Blocks im neuen Block aufgeführt</b> ist und in damit in den Hash des neuen Blocks eingeht. 
		<b> Diese verketteten Blöcke von Transaktionen nennt man "Blockchain"</b>
		</li>
		</ul>
		</p>
		<p>
 		<img height="75%" width="75%" src={netzv}  alt='block' />
		</p> 

 <Link to=".."><img src={Back} alt="zurück" style={{"float" : "left", "width" : "10%" , "padding-right" : 10}}></img></Link>		
 <br/><br/>
 
   </Main>
  </>
  );
}
