import BlockchainComponent from "../components/Blockchain";
//opt import { Link } from "react-router-dom";
//opt import props from 'prop-types';
import { Main } from "../components/styles/Main";
//opt import Product from "../components/Product/Product";
//opt import Lnk		from "../components/Lnk";



import Uhr		from "../bilder/uhr.png";


import Schloss	from "../bilder/schloss.png";


export default function EggTraining() {
//opt  const funcNav=props.funcNav;
//opt   	props.funcNav(false);

  return (
    <>
      <Main>
        <h1>EggTraining</h1>
        <hr />
<h2> !! Beim Verlassen dieser Training-Seite wird nichts gespeichert !!</h2>
Das Spiel beginnt bei jedem Seitenaufruf im <b>Grundzustand</b>.  
Dann sehen Sie unten auf dieser Seite den <b>1. Kontoauszug</b>, dem <b>Start-Block</b>, der die bestehenden 
Konten und deren Kontostände zeigt.  
<h2><u>Ihre Konten</u></h2>
Sie haben <b>Zugriff</b> auf die <b>Konten 3 und 13</b>, da Sie deren geheime, private Schlüssel kennen, die Sie 
zur <b>Signatur</b> von Überweisungen von diesen Konten benötigen:
<ol>
<b>Privater Schlüssel/Key</b> von Konto 3 ist <b>7=pr(3)</b> <br/>
<b>Privater Schlüssel/Key</b> von Konto 13 ist <b>37=pr(13)</b> 
</ol>
Die öffentlichen Schlüssel sind bei unserem fiktiven EggCoin immer gleich den Konto- bzw.  Account_Nummern:
<ol>
<b>Öffentlicher Schlüssel/Key</b> von Konto 3 ist <b>3</b> <br/>
<b>Öffentlicher Schlüssel/Key</b> von Konto 13 ist <b>13</b> 
</ol>
<h2> <u>Eingabe / Prüfung von Überweisungsaufträgen</u></h2>

<a href="Mod100"  target="_blank"><img src={Uhr} alt="Modulo" style={{"float" : "right", "width" : "21%" , "padding-right" : 0}}></img> </a>		
<div><i><b><small>(<b>Hilfe zur Egg-Signatur und der Modulo100 Berechnung</b>)</small></b></i></div>
<a href="CreSig"  target="_blank"><img src={Schloss} alt="Modulo" style={{"float" : "left", "width" : "14%" , "padding-right" : 17}}></img> </a>		
<ol>
<b>Überweisungsaufträge</b> von ihren Konten 3 bzw. 13 erstellen Sie unten in der Eingabemaske  <b>"Neue Transaktion eingeben".</b><br/>
</ol>
Die bei der Eingabe einer Transaktion / eines Überweisungsauftrags  erforderliche <b>Signatur</b> errechnen Sie, indem 
Sie im Abschnitt <b>"Potenzieren Modulo100"</b> ihren 
<ul>
<li>geheimen Schlüssel des Kontos im Feld <b>"Key" </b>, und den</li>
<li>angezeigten Wert <b>"Prüf-Nr" </b>im Feld <b>"P/S" </b> bzw. <b>"P-Nr/Sig"</b> eingeben.</li>
<li>Die Signatur erscheint dann im Feld <b>"ergibt"</b>.</li>
</ul>
Möchten Sie im Abschnitt "Potenzieren Modulo100" die <b>Signatur</b> einer in einem Block aufgeführten Transaktion auf ihre Echtheit <b>überprüfen</b>, dann 
<ul>
<li>geben Sie  im <b>Feld Key</b> die Kontonummer (Account) des Absenders aus dem Feld <b>"Von"</b> z.B. 9
 der Transaktion (z.B. TA 5, Block 0) und </li>
<li> im Feld <b>P-Nr/Sig</b> den Inhalt der Signatur ein (81). </li>
<li> Im Feld "ergibt" muss dann der Wert der P-Nr / des Hashs erscheinen (21).</li>
</ul>

<h2> <u>Verarbeitung / Mining der anstehenden Transaktionen</u></h2>
<p style={{"clear": "both"}} >
Ihre erfolgreich eingegebenen Überweisungen/Transaktionen (Signatur ist ok und der Kontostand ist hoch genug) 
<b>werden im Abschnitt "Anstehende Transaktionen" angezeigt.</b> <br/>
<b>Im Abschnitt "Ausgabe" "minen"</b> Sie einen neuen Kontoauszug/Block mit den anstehenden Transaktionen, indem das Programm in einem vorgegebenen Zeitabschnitt 
solange die Zahl <b>"Nonce" zufällig ändert</b>, bis der Hash mit einer 1 oder einer 3 endet. (simple Form eines Prove of Work)
<br/><br/> Läuft die Zeit ab, bevor eine passende Nonce-Zahl gefunden wurde, dann wird der Block in rot angezeigt, sonst in grün.
<br/><br/>
<font size="4">
	<b>Viel Spaß beim EGGCoin-Training</b><br/>
</font>	
</p>
	</Main>
	<BlockchainComponent />
	{// <Link to=".."><img src={Back} alt="zurück" style={{"float" : "left", "width" : "10%" , "padding-right" : 10}}></img></Link>		
	} <br/><br/>
 </>
  );
}
