import React from "react"; //opt  useState in geschw. Klammern
//opt import props from 'prop-types';
import {Link } from "react-router-dom";
import { Main } from "../components/styles/Main";



import Back		from "../bilder/back.png";





import GoChain 	from "../bilder/GoChain.png";
//import ImageComponent from "../components/ImageComponent";
export default function Spielregeln(props) {
//opt  const funcNav=props.funcNav;
//opt  props.funcNav(false);

	//console.log(window.location);
	return (

    <>
      <Main>
	
		<h2>Die Spielregeln von EggHacking</h2>
		<ul>

		<li><b><u>Spielregeln</u></b>
		<ol style={{"padding-left" : 2}}>
		<li>In der fiktiven Blockchain <b>EggCoin</b> können für 5 Konten mit den Nummern 3,7,11,13 und 19 alle Überweisungen eingesehen werden.</li>
		<li>Für die Konten 3 und 13 haben Sie zusätzlich die Berechtigung Überweisungen durchzuführen und </li>
		<li>für alle noch nicht verarbeiteten Überweisungsaufträge 
		können Sie den Verarbeitungsprozess, das Mining anstoßen, um einen neuen Block/Kontoauszug zu erstellen.
		</li>
		</ol><br/>
		Um eine gültige Überweisung von einem Konto durchzuführen müssen 2 Voraussetzungen erfüllt sein:
		<ol style={{"padding-left" : 2,"list-style": "none" }}>
		<li>- der Kontostand muss groß genug für die Überweisung sein </li>
		<li>- Sie müssen die Überweisung mit dem geheimen Schlüssel des Kontos signieren. </li>
		</ol><br/>
		*)<small><i><b>Hinweise zur Berechnung der Prüfnummern P-Nr und der Signaturen Sig sowie die privaten Schlüssel der Konten 3 und 13 finden Sie 
			 <a href="eggtraining"> hier.</a><br/><br/></b></i></small>
		</li>		



</ul>	
 <Link to=".."><img src={Back} alt="zurück" style={{"float" : "left", "width" : "10%" , "padding-right" : 10}}></img> </Link>		
<br/><br/>	
   </Main>
  </>
  );
}
