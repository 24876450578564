import sha256 from "crypto-js/sha256";
import { Main , Hashs } from "./styles/Main";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Blockchain } from "../lib/blockchain";
import AddBlock from "./AddBlock";
import Block from "./Block";
import { Breakpoint , BreakpointProvider, setDefaultBreakpoints  } from 'react-socks';
 function quersumme(forceOneDigit) {
    var z = forceOneDigit.toString().split('');
    for (var i=0, quer=0; i < z.length; quer+=z[i++]-0);
    //if( forceOneDigit && quer > 9) return quer.quersumme(forceOneDigit);
    return quer;
}
 function modpot( v , k) {
	 var rt = v;
	 for (var za =1; za < k; za +=1) {
		 rt = (rt * v)%100
	 }
	 return rt;
 }
 function verfgbar(acnt,blkchain) {
	var vin=0,vout=0;
	for (let i = 0; i <= blkchain.latestBlock().index; i++) {
		vout += blkchain.chain[i].transactions.filter(item =>item.from === acnt).reduce((ausg,tra) => parseInt(tra.amount)+ausg,0); 
		vin  += blkchain.chain[i].transactions.filter(item =>item.to 	 === acnt).reduce((ausg,tra) => parseInt(tra.amount)+ausg,0);
	}	 
  return vin-vout;
 }
 
export default function BlockchainComponent() {
  const [blockchain, setBlockchain] = useState(new Blockchain());
  const [chain, setChain] = useState(blockchain.chain);
  const [blocks, setBlocks] = useState(blockchain.blockchain);
  const [outputMessages, setOutputMessages] = useState([]);
  const [pendingTransactions, setPendingTransaction] = useState([]);
  const [values, setValues] = useState({
	basis: "",
	potenz: "",
	sigprfnr:"",
    from: "",
    to: "",
    amount: "",
    prfnr:"",
    sig:"",
	verf:"",
  });

  const [isLoading, setIsLoading] = useState(false);

 setDefaultBreakpoints([
  { xsmall: 0 }, // all mobile devices
  { small: 576 }, // mobile devices (not sure which one's this big)
  { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  { large: 992 }, // smaller laptops
  { xlarge: 1200 } // laptops and desktops
]);

  const set = (name) => {
//console.log(values);
	
    return ({ target: { value } }) => {
      setValues((oldValues) => ({
        ...oldValues,
        [name]: value,
		["verf"]: verfgbar(oldValues.from,blockchain),
		["prfnr"]: ((2*( quersumme(parseInt(oldValues.from))+quersumme(parseInt(oldValues.to))+
			quersumme(parseInt(oldValues.amount)))%10===5)+
		((quersumme(parseInt(oldValues.from))+quersumme(parseInt(oldValues.to))+quersumme(parseInt(oldValues.amount)))%2===0)+
        2*((quersumme(parseInt(oldValues.from))+quersumme(parseInt(oldValues.to))+quersumme(parseInt(oldValues.amount)))%10===4)+
		quersumme(parseInt(oldValues.from))+quersumme(parseInt(oldValues.to))+quersumme(parseInt(oldValues.amount)))%100
		,
		["sigprfnr"]: modpot(oldValues.basis,oldValues.potenz),
      }));
    };
  };

  const handleAddPendingTransaction = (e) => {
    e.preventDefault();
    if (values.from === "" || values.to === "" || values.amount === 0 ) {
      alert("Bitte Felder Von, An und Wert ausfüllen... Wert kann nicht 0 sein");
      return;
    }

	let encv = 1;
	let acnt = parseInt(values.from);
	
	if (acnt === 3){
		encv = 7;
	}
	else
	if (acnt === 27){
		encv = 3;
	}
	else
	if (acnt === 7){
		encv = 23;
	}
	else
	if (acnt === 23){
		encv = 7;
	}
	else
	if (acnt === 9) {
		encv = 9;
	}
	else	
	if (acnt === 11) {
		encv = 11;
	}
	else 
	if (acnt === 37) {
		encv = 13;
	}
	else 		
	if (acnt === 13) {
		encv = 37;
	}
	else 
	if (acnt === 17) {
		encv = 33;
	}
	else 		
	if (acnt === 19) {
		encv = 19;
	}
	else 
	if (acnt === 21) {
		encv = 21;
	}
	else 
	if (acnt === 33) {
		encv = 17
	}
	else   encv =1;
	acnt = acnt.toString();
	
	
	let sigcalc = modpot(values.prfnr,encv);
	console.log(values);
	console.log("sigcalc=",sigcalc,"encv=",encv);

	if (parseInt(values.sig) !== sigcalc) {
	    setValues({
		basis: "",
		potenz: "",
		sigprfnr:"",			
        from: "",
        to: "",
        amount: "",
        prfnr: "",
	    sig: "",
		verf:"",
		});
		alert("Signatur falsch!!");
		return;
	}
	let tmpAusg = pendingTransactions.filter(item =>item.from === acnt).reduce((ausg,tra) => parseInt(tra.amount)+ausg,0);
	if (values.from === acnt) {
		tmpAusg += parseInt(values.amount);
	}
	let tmpEing = pendingTransactions.filter(item =>item.to === acnt).reduce((ausg,tra) => parseInt(tra.amount)+ausg,0);
    if (values.to === acnt) {
	  tmpEing += parseInt(values.amount);
	}
	console.log(tmpAusg,tmpEing);
	let tmpTrAusg = tmpAusg;
	let tmpTrEing = tmpEing;
	for (let i = 0; i <= blockchain.latestBlock().index; i++) {
		tmpAusg += blockchain.chain[i].transactions.filter(item =>item.from === acnt).reduce((ausg,tra) => parseInt(tra.amount)+ausg,0); 
		tmpEing += blockchain.chain[i].transactions.filter(item =>item.to === acnt).reduce((ausg,tra) => parseInt(tra.amount)+ausg,0);
	}
	let tmpBlockAusg = tmpAusg - tmpTrAusg;
	let tmpBlockEing = tmpEing - tmpTrEing;
	
	if (tmpEing - tmpAusg <0) {
		tmpAusg=0;
		tmpEing=0;
		//pendingTransactions.splice(-1,1);
	    setValues({
        from: "",
        to: "",
        amount: "",
        prfnr: "",
	    sig: "",
		verf: "",
      });
	let vf=verfgbar(acnt,blockchain)+"";
		alert ("Zu wenig Coins (" + vf + ") im Konto");
		return;
	};
	alert("ok-Auftrag wird zum Mining vorgemerkt!");

	tmpEing=0;
	tmpAusg=0;
	
    let newTransaction = values;
    setPendingTransaction([...pendingTransactions, newTransaction]);
    blockchain.pendingTransactions.push(values);
    setValues({
      from: "",
      to: "",
      amount: "",
      prfnr: "",
	  sig: "",
	  verf: "",
    });
  };
  
function calculateHash(testNonce, data, prevHash,tS =  new Date( Date.now() ),ix=0 ) {
	let pzh=0, tst=JSON.stringify(data).split(",{");
    let lenbd=tst.length;
	if (lenbd>0) {
		for (let i=0; i<lenbd;i++) {
			console.log(tst[i]);
			pzh+=blockchain.quersumme(tst[i].replace(/[^0-9]/g,''));
		}
	}
	console.log(tS);
	if (tS) {
		pzh += blockchain.quersumme(tS.toString().replace(/[^0-9]/g,''));
	}
    pzh += blockchain.quersumme(parseInt((testNonce + prevHash+ ix).
	                                                toString().replace(/[^0-9]/g,''), 10)); 
	let	pz=(2*(pzh%10===5)+ (pzh%2===0)+pzh)%100;
//	console.log(testNonce,pzh,pz);
    return pz;
 
}
  function rndNonce() {
  var tn;
	return Math.floor( Math.random() * 10000 + 1 );
  }
  
  function computeHash(difficulty=blockchain.difficulty,tS=0) {
	if (tS===0) { 
		tS = new Date (Date.now());
	}; 
	let rnr = 0;
	let testNonce=rndNonce();
	let pTlngth=pendingTransactions.length;
    if (pTlngth !== 0) { 
    setIsLoading(true);
    setTimeout(() => {
      let messages = [];
	  let calch = 99999; 
	  let pHash=blockchain.latestBlock().hash;
	  let ix = blockchain.latestBlock().index + 1;
      setOutputMessages([]);
	  blockchain.stmpNonce(testNonce);
	  blockchain.tmpPoW=false;
// weli 20230213      while ( (!blockchain.tmpPoW) && (rnr++ <1000)) 
	  {
		calch=calculateHash(
          testNonce,
		  pendingTransactions,
          pHash,
		  tS,
		  ix
        );
		blockchain.tmpPoW=blockchain.pow(calch);
        messages.push(testNonce);
  	    blockchain.stmpNonce(testNonce); 
        testNonce=rndNonce();
		console.log(testNonce);
      }
      setOutputMessages([...messages]);
      setIsLoading(false);
      setTimeout(() => {
        //setOutputMessages([]);
        // weli 20230212 setPendingTransaction([]);
        blockchain.addNewComputedBlock(
          pendingTransactions,
          blockchain.latestBlock().hash,
		  tS,
		  blockchain.tmpNonce
        );
		setPendingTransaction([]);
        let newBlocks = [...blockchain.chain];
        setBlocks(newBlocks);
      }, 500);
    }, 20);
  }
  }

  const handleAddBlock = (data) => {
    const newChain = [...blockchain.addNewBlock(data)];
    setChain(newChain);
  };

  const handleDataChange = (block, data) => {
    let newChain = [...blockchain.updateBlockData(block.index, data)];
    setChain(newChain);
  };

  const handleNonceChange = (block, data) => {
    let newChain = [...blockchain.updateBlockNonce(block.index, data)];
    setChain(newChain);
  };

  return (
    <>
<Main>
	<Breakpoint small down>

  <TodoSectionsd>
        <Form >
			<p className='heading'>Potenzieren Modulo100 <ol> <small>Bs<sup>Key</sup> Mod100</small></ol> </p>
			<fieldset>
            <label htmlFor='basis'>
              P/S
              <input
                onChange={set("basis")}
                value={values.basis}
                name='basis'
				size='2'
				type="text" inputmode="numeric"				
				maxLength = {2}>
                </input>
            </label>
            <label htmlFor='potenz'>
              Key
              <input
                onChange={set("potenz")}
                value={values.potenz}
                name='potenz'
				size='2'
                maxLength = {2}></input>
            </label>
            <label htmlFor='sigprfnr'>
              ergibt
              <input
                onChange={set("sigprfn")}
                value={modpot(values.basis,values.potenz)}
                name='sigprfn'
				disabled="true"
				size='2'
                maxLength = {2}></input>
            </label>
<div />			
          </fieldset>
			<p> <br/> </p>
          <p className='heading'>Neue Transaktion eingeben</p>

          <fieldset>
            <label htmlFor='from'>
              Von
              <input
                onChange={set("from")}
                value={values.from}
                name='from'
				size='2'
				maxLength = {2}>
                </input>
            </label>
            <label htmlFor='to'>
              An
              <input
                onChange={set("to")}
                value={values.to}
                name='to'
				size='2'
                maxLength = {2}></input>
            </label>

			
            <label htmlFor='amount'>
              Wert
              <input
                onChange={set("amount")}
                value={values.amount}
                name='amount'
				size='2'				
               maxLength = {2}></input>
            </label>
            <label htmlFor='prfnr'>            
              P-Nr
               <input
                 onChange={set("prfnr")}
                 value={ ((2*( quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+
			quersumme(parseInt(values.amount)))%10===5)+
		((quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+quersumme(parseInt(values.amount)))%2===0)+
		2*((quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+quersumme(parseInt(values.amount)))%10===4)+
		quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+quersumme(parseInt(values.amount)))%100
				 }
                 name='prfnr'
				 disabled="true"
				 size='2'				 
                 ></input>
              </label>
            <label htmlFor='sig'>            
              Sig
               <input
                 onChange={set("sig")}
                 value={values.sig}
                 name='sig'
				size='2'></input>
              </label>
<div />
            <button type='submit' onClick={handleAddPendingTransaction}>
              Abschicken
            </button>
          </fieldset>
        </Form>
 <div className="Outputsd" style={{clear:"both",width:"75vw"}}>
 <PendingTransactions>
          <p className='heading'>Anstehende Transaktionen</p>
          {pendingTransactions.map((txn, idx) => (
            <Transaction
              key={`${txn.from}-${idx}`}
              from={txn.from}
              to={txn.to}
              amount={txn.amount}
              prfnr={txn.prfnr}
              sig={txn.sig}
            />
          ))}
  </PendingTransactions>
 </div>
  <div className="Outputsd" style={{clear:"both",width:"75vw"}}>
   <Output 
        outputMessages={outputMessages}
        isLoading={isLoading}
        computeHash={() => computeHash(blockchain.difficulty)}
    />

	  <p>
		<ul><li><i>Hat ein anderer Node hat <b>"POW-Prove of Work" </b>vorher erfüllt. <br/><small>(letze Ziffer Hash 1 oder 3)</small></i></li>
			<li><i>Dann wird Block rot angezeigt.</i></li>
		</ul>
	  </p>
	</div>  
</TodoSectionsd>
</Breakpoint>

<Breakpoint medium up>

<TodoSection>
        <Form >
			<p className='heading'>Potenzieren Modulo100 <ol> <small>Bs<sup>Key</sup> Mod100</small></ol> </p>
			<fieldset>
            <label htmlFor='basis'>
              P-Nr/Sig
              <input
                onChange={set("basis")}
                value={values.basis}
                name='basis'
				size='3'
				maxLength = {3}>
                </input>
            </label>
            <label htmlFor='potenz'>
              Key
              <input
                onChange={set("potenz")}
                value={values.potenz}
                name='potenz'
				size='3'
                maxLength = {3}></input>
            </label>
            <label htmlFor='sigprfnr'>
              ergibt
              <input
                onChange={set("sigprfn")}
                value={modpot(values.basis,values.potenz)}
                name='sigprfn'
				disabled="true"
				size='3'
                maxLength = {2}></input>
            </label>
<div />			
          </fieldset>
			<p> <br/> </p>
          <p className='heading'>Neue Transaktion eingeben</p>

          <fieldset>
            <label htmlFor='from'>
              Von
              <input
                onChange={set("from")}
                value={values.from}
                name='from'
				size='3'
				maxLength = {2}>
                </input>
            </label>
            <label htmlFor='to'>
              An
              <input
                onChange={set("to")}
                value={values.to}
                name='to'
				size='3'
                maxLength = {2}></input>
            </label>

            <label htmlFor='amount'>
              Wert
              <input
                onChange={set("amount")}
                value={values.amount}
                name='amount'
				size='3'
               maxLength = {2}></input>
            </label>
            <label htmlFor='prfnr'>            
              P-Nr
               <input
                 onChange={set("prfnr")}
                 value={ ((2*( quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+
			quersumme(parseInt(values.amount)))%10===5)+
		((quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+quersumme(parseInt(values.amount)))%2===0)+
		2*((quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+quersumme(parseInt(values.amount)))%10===4)+
		quersumme(parseInt(values.from))+quersumme(parseInt(values.to))+quersumme(parseInt(values.amount)))%100
				 }
                 name='prfnr'
				 disabled="true"
				 size='3'
                ></input>
              </label>
            <label htmlFor='sig'>            
              Sig
               <input
                 onChange={set("sig")}
                 value={values.sig}
                 name='sig'
				 size='3'
                ></input>
              </label>
<div />
            <button type='submit' onClick={handleAddPendingTransaction}>
              Abschicken
            </button>
          </fieldset>
        </Form>

 <PendingTransactions>
          <p className='heading'>
		  Anstehende Transaktionen</p>
          {pendingTransactions.map((txn, idx) => (
            <Transaction
              key={`${txn.from}-${idx}`}
              from={txn.from}
              to={txn.to}
              amount={txn.amount}
              prfnr={txn.prfnr}
              sig={txn.sig}
            />
          ))}
  </PendingTransactions>

    <Output 
        outputMessages={outputMessages}
        isLoading={isLoading}
        computeHash={() => computeHash(blockchain.difficulty)}
    />

	  <p> 
		<ul><li><i>Hat ein anderer Node <b>"POW-Prove of Work" (letze Ziffer Hash 1 oder 3)</b> beim,</i></li>
			<li><i>Mining vorher erfüllt, wird der Block rot angezeigt.</i></li>
			<li><i>Der Block ist natürlich gültig! </i></li>

		</ul>
	  </p>
</TodoSection>
</Breakpoint>


{//div className=Outputmu 
}
      
 <Breakpoint small down>
 {// <div className="BlockchainContainersd" >
 }
	<BlockchainContainersd>
        {chain.map((block) => (
          <Block 
            genisis={block.index === 0}
            key={block.index}
            type='blockchain'
            nonceChange={handleNonceChange}
            dataChange={handleDataChange}
            setChain={setChain}
            blockchain={blockchain}
            block={block}
          />
        ))}
    </BlockchainContainersd>
	
{//  </div>
}
 </Breakpoint> 
 
 <Breakpoint medium up>
 {//	<div className="BlockchainContainermu" style={{width:"100%"}}>
 }	
	<BlockchainContainermu>
        {chain.map((block) => (
		<Block
            genisis={block.index === 0}
            key={block.index}
            type='blockchain'
            nonceChange={handleNonceChange}
            dataChange={handleDataChange}
            setChain={setChain}
            blockchain={blockchain}
            block={block}
          />
        ))}
    </BlockchainContainermu>
{//div>
}
 </Breakpoint> 
</Main>
    </>
  );
}

const BlockchainContainersd = styled.div`
  display: flex;
  flex-direction:column-reverse;
  gap: 30px;
	height:100%;
  overflow-x: scroll;
`;


const BlockchainContainermu = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: scroll;
`;


function Transaction({ from, to, amount,prfnr, sig }) {
  return (
    <div className='transaction'>
      <p>
        <strong>Von: </strong>
        {from} &nbsp;
		<strong>An: </strong>
		{to} &nbsp;
        <strong> Wert: </strong>
        {amount}egg &nbsp;
		<strong>Prüf-Nr: </strong>
		{prfnr} &nbsp;
		<strong>Sig: </strong>
		{sig} &nbsp;

      </p>
    </div>
  );
}

function Output({ outputMessages, computeHash, isLoading }) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setMessages(outputMessages);
  }, [outputMessages]);

  return (
  <div>
 
  <Breakpoint small down>
  <div style={{"margin":"0" , width:"85vw"}}>
 	<OutputContainersd className="Outputsd" isLoading={isLoading}>
      Nonce: {messages[messages.length - 1]}&nbsp;&nbsp;
      <button onClick={computeHash}>Mining</button>
    </OutputContainersd>
	</div>

 </Breakpoint> 

  <Breakpoint medium up>
  	<OutputContainer isLoading={isLoading}>
      Nonce: {messages[messages.length - 1]}&nbsp;&nbsp;
      <button onClick={computeHash}>Mining</button>
    </OutputContainer>
  </Breakpoint> 	
  </div>
  );
}

const OutputContainersd = styled.div`

  margin: 1rem auto;
  max-width: 250px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #bbb;
  opacity: ${({ isLoading }) => (isLoading ? ".5" : "1")};
  .Outputsd {
	font-size:4rem;
  };	  

`;


const OutputContainer = styled.div`
  
  margin: 1rem auto;
  max-width: 250px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #bbb;
  opacity: ${({ isLoading }) => (isLoading ? ".5" : "1")};
`;


const Form = styled.form`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #bbb;
  fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 200px;
    margin: auto;

  }

  label {
    display: flex;
    justify-content: space-between;
  }

  
  input {
	color: #010101;
   font-size: 0.8rem;	
   font-weight: 400;	
  }  

  button {
    width: 80px;
    align-self: flex-end;
  }
`;

const PendingTransactions = styled.div`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #bbb;

  p {
    margin: auto;
  }
  .transaction-list {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 1rem;

    max-height: 200px;
  }
  .transaction {
    display: flex;
    gap: 3rem;
    background: #efefef;
    border: 1px solid #333;
    border-radius: 0.25rem;
    padding: 0.5rem;
  }
`;

const TodoSectionsd = styled.section`
  display: flex; flex-wrap: wrap;
  gap: 3px;
  padding:10px;

  p {
	margin: 0;
  }

  input {
	color: #010101;
   font-size: 0.8rem;	
   font-weight: 400;	
  } 
  .heading {

    font-size: 1.25rem;
    font-weight: 600;
    color: #555;
    text-decoration: underline;
	margin-bottom: 2;
  } 
`;

const TodoSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  max-width: 1000px;
  margin: auto;

  p {
    margin: 0;
  }

  .heading {
    font-size: 1.25rem;
    font-weight: 600;
    color: #555;
    text-decoration: underline;
    margin-bottom: 0.25rem;
  }
`;

export const AddNewButton = styled.button`
  margin: auto 20px;
  height: 100px;
  width: 70px;
  border: none;
  border-radius: 10px;
  background-color: #ccccff;
  font-size: 50px;
  cursor: pointer;
  box-shadow: 0 0 5px #ddd;
  transition: 0.2s;
  :hover {
    box-shadow: 5px 5px 20px #ddd;
    transform: scale(1.05);
  }
`;
