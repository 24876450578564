import React from "react";
//opt import { Link } from "react-router-dom";
//opt import props from 'prop-types';

import { Main } from "../components/styles/Main";
import auftrag0 from "../bilder/auftrag0.png";

export default function EggBsp2(props) { 
//opt   const funcNav=props.funcNav;
//opt   	 props.funcNav(false);

  //console.log(props);  
  //console.log(window.location);
  return (
  
    <>
      <Main>
        <h1>EggCoin's einfacher Hash (Prüfnummer P-Nr) sowie die einfache Signatur (Sig)</h1>
		Prüfnummern werden sehr unterschiedlich eingesetzt. <br/>
		<ul><li>So bilden bei der <b>IBAN</b> die 2 Stellen hinter dem Länderkennzeichen <b>DE</b>
		    </li>
		</ul> 
		eine Prüfnummer,deren Wert sich ändert, wenn bei der Eingabe der restlichen Zahlen Eingabefehler 
		gemacht werden oder Zahlendreher auftreten. Die IBAN Prüfnummern dienen als <b>Eingabekontrolle</b> für die richtige Eingabe.
		(Unter dem Punkt "Hashs" des Hauptmenüs können Sie IBAN-Prüfziffer per Programm erstellen lassen).<br/>
		<ul>
		<li>Die Prüfnummern bei den <b>elektronischen Signaturen</b> 
		</li>
		</ul> 
		sind sogenannte <b>"Einwegfunktionen" </b> und werden <b>"Hash" </b>  (das Hash - engl. Durcheinander) genannt.
		Sie ordnen einem Text oder irgend einer Eingabe eine sehr große Zahl zu, die sich schon bei kleinsten 
		Änderungen der Eingabe unvorhersehbar ändert (siehe Punkt "Hashs" im Menü). Daher kann man zur einem vorgegebenen aber bisher unbekannten Hash rückwärts keine Eingabe finden 
		die diesen Hash erzeugt.<br/>
    <hr />
<p>
<div><i><b><small>(Prüfnummer P-Nr am Beispiel einer EggCoin Überweisung)</small></b></i></div>
<img height="70%" width="70%" src={auftrag0} style={{"padding" : 5}} alt='auftrag0' />

Beim EggCoin verwenden wir einen simplen Hash (Prüfnummer) mit sehr einfacher Berechnung: 

<ul style={{"padding-left" : 1}}>
<li>man bildet zunächst die <b>Quersumme</b> der Zahlen "Von", "An", "Wert" also 3,13 und 1
	=> 3+1+3+1 <b>= 8,</b> 
</li>
<li>	erhöht sie um 1, falls die Quersumme eine gerade Zahl ist <br/>(<b>hier also P-Nr auf 9</b>, weil 8 eine gerade Zahl ist) 
</li>
<li>	und falls die letzte Ziffer der Quersumme oder der um 1 erhöhten Zahl 5 ist, <b>setzt man die 
	letzte Ziffer auf 7</b>. 
</li>
</ul>
	
	<i>( Der Grund für diese zunächst kompliziert erscheinende Regel liegt in der Mathematik / Zahlentheorie. Wegen 
		unserer Rechenvereinfachung auf die letzten beiden Ziffern (Modulo100) dürfen bei der anschließend durchgeführten sehr einfachen Verschlüsselung die 
		<b> Zahlen nicht durch 2 oder 5 teilbar</b> sein, die Teiler von 100 = 2*5*2*5  sind) 
	</i>
	
	</p>
	Wie der Ersteller der Transaktion mit seinem Privat-Schlüssel aus dem Hash (P-Nr) die Signatur 69 erstellt, wird auf einer gesonderten 
	 <a href="cresig"> Seite </a> erklärt. 
	Bei den echten <b>Bitcoin & Co.</b> erstellt das <b>Wallet-Programm</b> des Senders  Hash und  Signatur.
	<hr/>
	Ob die Signatur 69 wirklich vom Account 3 erzeugt wurde, kann man aus den Informationen der Transaktion wie folgt überprüfen:<br/>

	<ol  style={{"padding-left" : 1}}>
	
	<li>
	Der zum <b>Account 3</b> gehörende <b>"Öffentliche-Schlüssel" </b> zur Entschlüsselung der Signatur 69 ist beim<b> EggCoin immer 
	gleich der Account Nummer</b> also <b>3</b> 
	<ol>
	<i>
		(Bei Bitcoin und Co. wird der öffentliche Schlüssel mit der Transaktion versendet 
		und die Account-Nummer aus dem öffentlichen Schlüssel berechnet)
	</i>
	</ol>
	<br/>
	</li>
	
	<li>Die <b>Account-Nr</b> als öffentlicher Schlüssel gibt bei unserem Spiel <b>an, wie oft </b> man die <b>Signatur mit sich selbst malnehmen</b> (potenzieren) muss und 
	immer nur mit den letzten beiden Ziffern rechnet:<br/><br/></li>
	
	<li>
	Mit dem öffentlichen Schlüssel 3 ergibt sich bei der Entschlüsselung der Signatur 69 im obigen Beispiel<br/>
	<b> (SiG hoch 3) Modulo 100 = <br/>
	(69 * 69 * 69) Modulo100 = <br/>(</b>47<b><u>61</u> * 69) Modulo100 = <br/>(61 * 69) Modulo100 = (</b>42<b><u>09</u>) Modulo100 = <u>9</u> </b><br/></li>
</ol>

	<p>Jeder kann sich die Prüfziffer selbst ausrechnen kann und mit dem entschlüsselten Signaturwert vergleichen. Ist die berechnete Prüfziffer gleich dem 
	entschlüsselten Signaturwert, kann man davon ausgehen, dass der Sender den geheinem Schüssel zum Account 3 hatte. <br/>
	
	</p>
	{//	<Link to=".."><img src={Back} alt="zurück" style={{"float" : "left", "width" : "10%" , "padding-right" : 10}}></img> </Link>		
	}<br/><br/>	

	</Main>

    </>
  );
}
