import sha256 from "crypto-js/sha256";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Main } from "../components/styles/Main";
import { Hashs } from "../lib/hashs";

 //function quersumme(forceOneDigit) {
 //   var z = forceOneDigit.toString().split('');
 //   for (var i=0, quer=0; i < z.length; quer+=z[i++]-0);
    //if( forceOneDigit && quer > 9) return quer.quersumme(forceOneDigit);
 //   return quer;
//}
 function modpot( v , k) {
	 var rt = v;
	 for (var za =1; za < k; za +=1) {
		 rt = (rt * v)%100
	 }
	 return rt;
 }
 
export default function ZahlensysComponent() {
  const [hashs, setHashs] = useState(new Hashs());
  const [data, setData] = useState(hashs.data);
  const [hash, setHash] = useState(hashs.hash);

  const [hashxy, setHashxy] = useState(hashs.hashxy);
  const [outputMessages, setOutputMessages] = useState([]);
  const [values, setValues] = useState({
    eingabe: "",
	ein2hex: "",
	base58: "",
    prfqs:"",
    prfnr:"",
	dtasha:"",
	dtashadec:"",
	dtabase58:"",
  });
  const [isLoading, setIsLoading] = useState(false);

 
  const set = (name) => {
//console.log(values);
	
    return ({ target: { value } }) => {
      setValues((oldValues) => ({
        ...oldValues,
        [name]: value,
		["ein2hex"]: hashs.dtaHex(oldValues.eingabe),
		["prfqs"]: hashs.querSumme(oldValues.eingabe)%100,
		["prfnr"]: hashs.pruefNummer(oldValues.eingabe)%100,
		["iban"]:  hashs.iban(oldValues.eingabe),
		["base58"]:  hashs.toBase58(oldValues.eingabe),
		["dtasha"]:  hashs.secHash(oldValues.eingabe),
		["dtashadec"]:hashs.secHashDec(oldValues.eingabe),
		["dtabase58"]:hashs.secToBase58(oldValues.eingabe),
    }));
  };
  }
  
//0x16 58^2=3364 | 61: 5*58=290 0x122 | A1: 9*58=522 0x20a | 56*58=3248 57*58=3306 57*58 + 54 =3363
// 0x1ceeb25aa9931466dfda599653d827c8fe70e9a20278ebe5744206ed165f54e7
//76949280022739288613800702199389566746553352754411619296370143719059980932905
//var eing = strng.startsWith('0x') && strng.length >2?strng.substring(2, strng.length):decToHex(strng);//this.secHash(strng);
//Thu Oct 06 2022 14:58:33 GMT+02 00 (Mitteleuropäische Sommerzeit) 9 3 10 13 73 9 7 10 17 97 9 11 10 13 73 9 13 10 17 97 9 19 10 21 81
// 38 + 44 + 59 + 61 = 202
//39
//1 7875 3 Mon Jan 09 2023 15:13:46 GMT+0100 (Mitteleuropäische Normalzeit) 3 13 1 9 69
  return (
  
      <>
  <Main>
        <h2>Zahlensysteme</h2>
		<hr/>

	<ul>
	<li><b><u>Dezimalzahlen</u></b>
	<br/>ein Preis von 134 Euro sagt uns 1x100 + 3x10 + 4 Euro
	<br/>
	Es gibt die Zeichen '0','1','2',...'9' Ziffern genannt mit den Werten 0,1,2,...9 wenn sie ganz rechts stehen, dem 10-fachen Wert eine Stelle weiter links, 
	10x10 die 3. Stelle usw. jeweils den 10-fachen Wert als der rechte Nachbar.
	
	</li><br/>
	<li><b><u>12-adisches Stellenwertsystem (Gross/Dutzend "Eierwert")</u></b>
	<br/>Meine Oma hat für ihr Lebensmittelgeschäft in den 50iger Jahren pro Woche beim Lieferanten 1 Gross und 3 Dutzend  Eier bestellt
	Das Gross war eine Palette mit 12 Reihen zu je 12 Plätzen also 12x12=144 Eier und 3 kleine Päckchen mit je 12 Eier. Zusammen 180 Eier für 6 Tage.
	Das 12er Päckchen wurde Dutzend, die 12*12 große Palette wurde Gross genannt. Heute sind im kleinen Karton 10 und
	auf der Palette 100 Eier. Das Dutzend/Gross System wird nicht mehr verwendet.
	</li><br/>
	<li><b><u>Binärsystem</u></b>
	<br/>Es gibt nur die Zeichen/Ziffern '0' und '1' mit den Werten 0 und 1 und der linke Nachbar hat jeweils einen doppelt so großen Wert:<br/>
	<b>binär 1010</b> hat den Wert dezimal 1x8 + 0x4 + 1x2 + 0x1 = dezimal <b>10</b>
	</li><br/>
	<li><b><u>Hexadezimalzahlen</u></b><br/>
	 Eine Zahl im Hexadezimalsystem kann aus den Zeichen/Ziffern '0','1','2',..'9','a','b','c','d','e','f' zusammengesetzt sein, wobei a den Wert 10, 
	 b den Wert 11 bis f den Wert 15 hat und eine Stelle weiter links den 16-fachen Wert hat:<br/>
	 <b>0x2a2f</b> hat den Wert dezimal 2x16x16x16 + 10x16x16 + 2x16 + 15 = 2x4096 + 10x256 + 2x16 + 15 = dezimal <b>10.799</b>
	 	 </li>
	</ul>
	<br/> Die bei Bitcoin und Co. vorkommenden Zahlen sind sehr groß. Eine Bitcoin Adresse hat im binären Zahlensystem 160 Stellen ( Bit 0,1)<br/>
	Im Dezimalsystem sind es<br/> 
	<b>2 hoch 160 = <br/>1.461. 501.637.330. 902.918.203. 684.832.716. 283.019.655. 932.542.976</b>  Bitcoin-Adressen. Eine Zahl 
	mit 49 Dezimalstellen! <br/>Die Anzahl Sandkörner auf der ganzen Welt schätzt man auf weniger als <br/><b>2 hoch 63 =  9.223.372.036.854.775.808</b>
	<br/><br/>
	Es gibt somit 1.000.000.000.000.000.000.000.000.000.000 mal mehr Bitcoin Adresse als Sandkörner auf der Welt!
	Im Hexadezimalsystem lassen sich Zahlen mit 160 Binärstellen mit 40 Zeichen aus 0,1,..9,a,b,c,d,e,f darstellen.<br/>
	<br/>
	Um die Schreibweise auf 27 - 34 Zeichen zu verkürzen, verwendet Bitcoin das Base58 System: <br/> 
	<ul>
	<li> <b><u>Base58</u></b> <br/>
	verwendet die Zeichen/Ziffern <b>123...9</b> (ohne die 0) für die Nennwerte <b>0 bis 8</b>,<br/>
		die Großbuchstaben <b>ABCDEFGHJK..Z</b> ohne O (großes o) und ohne I (großes i) für die Nennwerte <b>9 bis 32</b> sowie <br/>
		die Kleinbuchstaben <b>abc..ijkmn..z</b> ohne l (kleines L) für die Nennwerte <b>33 bis 57</b><br/>
		(Auf die herausgenommenen Zeichen wird wegen der Verwechselungsgefahr verzichtet.)<br/>
		Also insgesamt 9+24+25 = 58 Zeichen.<br/>
		für seine Nennwerte von 0 bis 57. Der linke Nachbar einer Ziffer hat jeweils einen 58 mal so großen Wert. Beispiel:<br/> 
		<b>3365</b> dezimal ist 58<sup>2</sup> + 1 also dezimal (2-1)*58*58 +(1-1)*58 + (2-1)  in Base58 <b>212</b> (das Zeichen "1" hat in Base58 den Wert Null und das Zeichen "2" den Wert 1)<br/><br/>

		</li>
		</ul>
	<hr/>
<br/>	

<u>Geben Sie folgende Werte in das Eingabefeld ein:</u><br/><br/>
<b>0x16</b> ist dezimal 1*16 + 6 = 22 und in Base58 <b>"P" </b> - <br/>das 23. Wert-Zeichen mit Wert 22, da das 1. Base58 Zeichen den Wert 0 hat.<br/> <br/> <b>Base58: "1,2,..9,"</b>(9 Zeichen)<b>
,"A,B,..H,"</b>(8) I fehlt <b>"J,K,...N," </b>(5) O fehlt <b>"P"</b>(1) insgesamt <b>9 + 8 + 5 +1 = 23 </b> Zeichen. <br/><br/> 



 
</Main>
	<TodoSection>
        <Form>
          <p className='heading'>Verschiedene Prüfziffern (Hashs) ausprobieren</p>
          <fieldset>
            <label htmlFor='eingabe'>
              Eingabe&nbsp;&nbsp;
              <input
                onChange={set("eingabe")}
                value={values.eingabe}
				size='28'
                name='eingabe'>
               </input>
            </label>

            <label htmlFor='ein2hex'>            
               dez|hexa&nbsp;
               <input
                 onChange={set("ein2hex")}
                 value={hashs.dtaHex(values.eingabe)
				 }
				size='28'

                 name='ein2hex'
				 disabled="true"
                 >
				</input>
              </label>

            <label htmlFor='base58'>            
               base58 &nbsp;&nbsp;&nbsp;
               <input
                 onChange={set("base58")}
				 value={ hashs.toBase58(values.eingabe)}
				size='28'
                 name='base58'
				 disabled="true"
                 >
				</input>
              </label>

            <label htmlFor='prfqs'>            
              Quersumme&nbsp;&nbsp;
               <input
                 onChange={set("prfqs")}
                 value={hashs.querSumme(values.eingabe)%100
				 }
				 size='2'
                 name='prfqs'
				 disabled="true"
                 >
				</input>
              </label>


           <label htmlFor='prfnr'>            
             Prüfnummer&nbsp;
               <input
                 onChange={set("prnr")}
                 value={hashs.pruefNummer(values.eingabe)%100
				 }
				 size='2'
                 name='prfnr'
				 disabled="true"
				 >
				 </input>
              </label>

           <label htmlFor='iban'>            
             2 IBAN Prüfziffern hinter DE&nbsp;&nbsp;
               <input
                 onChange={set("iban")}
                 value={hashs.iban(values.eingabe)
				 }
				 size='2'
                 name='iban'
				 disabled="true"
				 >
				</input>
              </label>

 
          </fieldset>
        </Form>

      </TodoSection>
	  
      <hr />

    </>
  );
}


const TodoSection = styled.section`
 display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;


  p {
    margin: auto;
  }

  .heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: #555;
    text-decoration: underline;
    margin-bottom: 0.25rem;
  }
`;


const Form = styled.form`

 display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
flex-flow: column wrap;
font-size: 1.0rem;
font-weight: 600;
  padding: 10px;

  border-radius: 10px;
  border: 1px solid #bbb;
  margin:auto;
  
  fieldset {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
   flex-direction: column;

    gap: 0.5rem;
    margin: 2%;
  }

  label {
	color: #505050;
    justify-content: space-between;
  }

  
input {
font-size: 0.8rem;
font-weight: 600;	
color #404040;	

}  
input:disabled {
font-size: 0.8rem;	
font-weight: 600;
color #404040;	
}

`;