import React from "react";
//opt import props from 'prop-types';
import { Link } from "react-router-dom";
//opt import Block from "../components/Block";
import { Main } from "../components/styles/Main";
//opt import Product	from "../components/Product/Product";
//opt import Lnk		from "../components/Lnk";
//opt import PointAdd from "../bilder/point-addition.png";
import AnimECC  from "../bilder/animation-tangent-line.gif";
import Mod10   from "../bilder/mod10.jpg";
import Mod100a from "../bilder/mod100a.png";
import Mod100b from "../bilder/mod100b.png";
//opt import kompass from "../bilder/kompass.png";
import Uhr     from "../bilder/uhr.png";

export default function Signatur(props) { 
 // const funcNav=props.funcNav;
  const state={
	lnk: [ {id:1, imgUrl: Uhr,stl:{float:"left"},w:"10%",h:"10%",mw:"100vh",mh:"100vh", lnkUrl: "Mod100"},
		   {id:2, imgUrl: AnimECC, stl:{float:"left",width:"25%"},w:"15%",h:"25%",mw:"100vh",mh:"100vh", 
		   lnkUrl: "",title: "Eliptische Kurven"},
	],
    post: [
      { id: 1, name: Mod10 ,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
      { id: 2, name: Mod100a ,
	  	w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
      { id: 3, name: Mod100b ,
	  	w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
	],
  };
  //console.log(props);  
  //funcNav(true);
  return (
  
    <>
      <Main>

        <h1>Signaturen</h1>
		<hr/>
		<h2>(RSA/PGP-, DSA-, ECDSA-Signaturen)</h2>
		Um durch eine Software zu überprüfen, dass eine Nachricht, ein Dokument, eine Transaktion, ein Personalausweis usw.
		von einer in der Nachricht angegeben Quelle stammt (Person, Gerät, Debit-Karte usw.) und nachträglich nicht verändert wurde, werden <b>Elektronische Unterschriften / 
		Signaturen</b> verwendet.<br/><br/>
		
		Das Grundprinzip elektronischer Signaturen besteht aus drei Schritten:<br/>
	<b><i><ol>
		<li>Mit einem veröffentlichten Berechnungsverfahren, das in vielen Apps einprogrammiert ist,  wird aus einer beliebig langen Nachricht, eine
		einzige Zahl berechnet, das <b>Hash </b> der Nachricht.
		</li>		
		<li> Im darauf folgenden Schritt wird aus dieser Hash-Zahl mit einer weiteren Zahl, dem <b>geheimen Schlüssel</b> der Nachrichtenquelle, nach einem ebenfalls 
		öffentlich bekannten Berechnungsverfahren eine <b>Signatur </b> berechnet, meistens bestehend aus einer oder zwei Zahlen.
		</li>
		<li>Zu jedem privaten Schlüssel gibt es zu jeder "Quelle" (z.B. einer Absender-Adresse einer Email, einer Kontonummer, einer Personalausweisnummer, usw.) einen
		<b> öffentlichen Schlüssel, </b> der jedem bekannt ist, der die Signatur überprüfen möchte. (z.B. wird er in der gesendeten Nachricht mit aufgeführt) 
		</li>
	</ol></i>
	</b>
	<u>Hier ein simples Beispiel mit unserem sehr vereinfachten Verfahren zur <b>Demonstration: </b></u><br/><br/>
		Bei unserem fiktiven EggCoin wird die Signatur durch Verschlüsselung des Hash-Werts der Nachricht
	gebildet. Gerechnet wird allderdings mit den zwei letzten Stellen, also Modulo 100. Als Hash nehmen wir die letzten beiden Ziffer der Quersumme 
	aller Ziffern in der Nachricht und erhöhen diese bei geraden Zahlen um 1. Wenn das Ergebnis durch 5 teilbar ist, dann wird die letzte Stelle auf die Ziffer
	7 geändert. Dieser Hash wird mit dem privaten Schlüssel (einer ebenfalls zweistelligen Zahl Modulo 100 potenziert (Bei allen Berechnungen
	 wird nur mit den 2 letzten Ziffern weiter gerechnet).
	<ul>
		<li><b>Simple-Hash(mod10): </b><br/>Betrachten wir nur die in einer Nachricht vorkommenden Ziffer 0,1,2,3..9 und zählen diese zu einer <b>Quersumme</b> zusammen.
		Alle anderen Zeichen überlesen wir. Als Hash nehmen wir die letzte Ziffer dieser Quersumme: <br/>
		<br/>
		<ol>Hash der Nachricht <b>"Konto 3 sendet 1 Euro auf Konto 8"</b> ist letzte Ziffer von 3 + 1 + 8 = 1<b>2</b> also <u><b>Hash=2 </b></u></ol>
		</li><br/>	
		<li><b>Berechnungsverfahren </b>zur SimpleSignatur-Erstellung (mod10 Erläuterungen
		<a href="Mod100"> hier</a>): 
		<br/>Multipliziere den Hash so oft mit sich selbst, wie der geheime 
		 Schlüssel angibt und nehme jeweils die letzte Ziffer des Ergenisses als SimpleSignatur.
		</li><br/>
		<li><b>Signaturerstellung </b> mit dem <u><b>Schlüsselpaar (7,3)</b></u><br/>
		(der geheime, private Schlüssel ist gleich <b>7</b> und der dazugehörige öffentliche Schlüssel ist gleich <b> 3</b> )<br/><br/>

		 <b> Signatur = Hash <sup> geheimer Schlüssel</sup><small><sub>|mod10</sub></small> = 2<sup>7</sup> <small><sub>|mod10</sub></small> = 2*2*2*2*2*2*2 <small><sub>|mod10</sub></small> = 128 <small><sub>|mod10</sub></small> = 8</b><br/>
			 <u><b> Signatur = 8</b></u></li><br/>
			 
		<li> Zur Prüfung wird die Signatur so oft mit sich selbst multipliziert, wie der öffentliche Schlüssel angibt und die letzte Ziffer des Ergebnisses muss wieder den Hash ergeben!<br/>
			<b>Signatur<sup>öffentlicher Schlüssel</sup>=8<sup>3</sup>=8*8*8=512</b><br/><br/>
            mit <b>2</b> als letzter Ziffer ist wieder der Hash der Nachricht <b>=> die Signatur ist verifiziert.</b>

		</li>			
	</ul>

	Bei einer solch einfachen Hash-, und Signatur-Berechnung, wie im Beispiel kann man die Nachricht leicht verändern und damit die Unterschrift fälschen:<br/>
	<ul>
		<li>Bei der ursprünglichen Nachricht <b>"Konto 3 sendet 1 Euro auf Konto 8"</b> wurde die Signatur <b>8</b> mit versendet</li><br/>
		<li>ich möchte, dass die Bank von Konto 3 nicht an Konto 8 sondern auf mein Konto 9 überweist.
		d.h. ich muss die Signatur zur Nachricht <b>"Konto 3 sendet 1 Euro auf Konto 9"</b> so erraten, dass sie mit dem öffentlichen Schlüssel von Konto 3, also der <b>3 </b>
		verifiziert werden kann?? Aber ich kenne den privaten Schlüssel zum öffentlichen Schlüssel <b>3 </b>nicht".<br/>
		</li><br/>
		<li> Die Hash-Zahl zu meiner gefälschten Nachricht ist <b>Hash = </b>letzte Ziffer von 3+1+9 <small><sub>|mod10</sub></small>= 13<small><sub>|mod10</sub></small> =<b>3</b>
		</li><br/>
		<li> Das bedeutet: ich suche eine Zahl, die drei mal mit sich selbst multipliziert an der letzten Ziffer den Hash also 3 ergibt.<br/>
		2*2*2=8 <b>falsch</b>, 4*4*4 = 64 mit letzter Ziffer 4 <b>falsch</b>, 5*5*5 = 12<b>5 falsch</b>, 6*6*6 = 21<b>6 falsch</b><br/>
		7*7*7=34<b>3 richtige Endziffer 3</b>
		</li><br/>
		</ul>
	Zur Bestimmung einer Signatur, wenn der private Schlüssel nicht bekannt ist, <b>bleibt nur das Ausprobieren</b> 
	 oder die Nachricht so verändern, dass sich das Hash und damit die Signatur nicht ändert!<br/><br/>
	 <h3>Nun zu den Signaturverfahren bei Bitcoin & Co.</h3>
	 Auch bei Bitcoin und Co. wird aus dem Hash der Transaktion oder des Blocks mit einer nur dem Unterzeichner bekannten ganzen Zahl, dem privaten Schlüssel, eine Signatur (r,s) erstellt, die allerdings aus zwei ganzen Zahlen r und s
	 besteht. Zu dem privaten Schlüssel gibt es jeweils einen zugehörigen öffentlichen Schlüssel, der auch aus zwei ganzen Zahlen x und y besteht. Mit seiner Hilfe läßt sich die Signatur bestätigen lässt.<br/>
	 Um an unserem Spiel "EggCoin-Hacking" teilzunehmen und für das grundsätzliche Verständnis der Blockchains, <ol><b>sind die nachfolgenden Erläuterungen zur Signatur auf Basis Eliptischer Kurven nicht notwendig!</b></ol>  
	 Im Gegensatz zu unseren zweistelligen Zahlen werden bei Bitcoin & Co. sehr große Zahlen (78 Dezimalstellen) verwendet. Sie machen das Durchprobieren ("Brute-Force") zur Suche nach dem unbekannten 
	 privaten Schlüssel, der zur Fälschung der Unterschrift bei Manipulationen notwendig ist, praktisch unmöglich.
<hr/>
	Bitcoin und Co. verwenden zur Berechnung ihrer Signaturen das Verfahren <b>"Elliptic Curve Digital Signature Algorithm" </b> abgekürzt <b>"ECDSA"</b>.	Das ECDSA-Verfahren ist mathematisch anspruchvoller als unsere am RSA-Verfahren angelehnte Verschlüsselung durch Potenzieren Modulo 100. 
	Das Verfahren nutzt aus, dass eine Gerade durch zwei Punkte <b>P</b> und <b>Q</b> (im Bild unten sind die Gerade und die Punkte P,Q gelb gezeichnet) auf einer eliptischen Kurve diese Kurve genau noch einmal in einem Punkt R' schneidet. 
	<br/>Der an der x-Achse gespiegelte Punkt <b>R</b> (Spiegelung und Punkt R rot gezeichnet) liegt wieder auf Kurve und man definiert ihn als die Summe der Punkte P und Q. Ist P = Q, dann schneidet die Tangente an die Kurve im Punkt P die Kurve ebenfalls in einem Punkt R' 
	und der an der x-Achse gespiegelte Punkt R wird als Summe von P mit sich selbst definiert:
	<ol>
	<b>P &oplus; Q =: R &nbsp;</b> und  &nbsp;<b> P &oplus; P =: 2&otimes;P</b> &nbsp; usw. &nbsp; <b> (2&otimes;P) &oplus; P =: 3&otimes;P, &nbsp; 4&otimes;P. ...</b> 
</ol>
<br/> 
 
 <br/><div><i><b><small>(Weitere Erläuterungen mit Klick auf das Bild von <a href="https://andrea.corbellini.name/2015/05/17/elliptic-curve-cryptography-a-gentle-introduction/" target="_blank" rel="noreferrer" >A. Corbellini </a>)</small></b></i></div>
  <Link to="/ECDSA"><img src={AnimECC} alt="ECDSA" style={state.lnk[1].stl}></img> </Link>			
	
	</Main>

		
    </>
  );
}
