//opt import React, {useState } from "react";
//opt import props from 'prop-types';
import { Link } from "react-router-dom";
import { Main } from "../components/styles/Main";
import Product from "../components/Product/Product";
import Block0   from "../bilder/block0.jpg";
import Block01  from "../bilder/block01.jpg";
import Block012 from "../bilder/block012.png";
import Uhr		from "../bilder/uhr.png";
import Bitcoin		from "../bilder/bitcoin.png";
import Prfsig	from "../bilder/prf.jpg";
import Schloss	from "../bilder/schloss.png";
import auftrag0 from "../bilder/auftrag0.png";
import Egg7vorn	from "../bilder/7v.png";
import netz   from "../bilder/netz.gif";
import Netz13 from "../bilder/netz13.png";

export default function EggCoins(props) {
  const funcNav=props.funcNav;
  funcNav(true);
  const state={
	lnk: [ 	{id:1, imgUrl: Uhr,w:"10%",h:"10%",		stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "Mod100"},
			{id:2, imgUrl: Bitcoin,w:"10%",h:"10%",	stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "CryptoFiat"},
			{id:3, imgUrl: Prfsig,w:"10%",h:"15%",  stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "PrfSig"},
			{id:4, imgUrl: Schloss, w:"8%",h:"10%",stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "CreSig"},	  
			{id:5, imgUrl: auftrag0, w:"75%",h:"75%",stl:{clear:"both"},mw:"100%",mh:"100vh", lnkUrl: ""},	  
	  		{id:6, imgUrl: Egg7vorn, w:"50%",h:"50%",stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "Spielregeln"}],	  
    post: [
      { id: 1, name: Block0, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
      { id: 2, name: Block01, name2: Block01,
		w:"50%",h:"50%",stl:{float:"left", "padding" : 10},  
		mw:"100%",mh:"100%", mstl:{clear:'both', "padding-right" : 10}},
      { id: 3, name: Block012, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
	  { id: 4, name: Netz13, name2: netz,
		w:"95%",h:"95%",stl:{align:"left",clear:"both"},
		mw:"95%",mh:"95%", mstl:{clear:'both'}},		
	  { id: 5, name: netz, name2: undefined,
		w:"15%",h:"15%",stl:{float:"left"},
		mw:"75%",mh:"75%", mstl:{clear:'both'}},		

    ]
  };
	//console.log(window.location);
	return (

    <>

      <Main>		
	  	
        <h1>EggCoins</h1>
		<hr/>
		<div><i><b><small>(Spielregeln mit Klick auf das Bild)</small></b></i></div>
  <Link to="Spielregeln"><img src={Egg7vorn} alt="Spielregeln" style={{"float" : "left", "width" : "50%" , "padding-right" : 10}}></img> </Link>		
		{//		<a href="Spielregeln"  target="_blank"><img src={Egg7vorn} alt="Spielregeln" style={{"float" : "left", "width" : "50%" , "padding-right" : 10}}></img> </a>
}
		Wie funktioniert die Eröffnung eines Kontos und die Überweisung elektronischer Währungen über das Internet
		ohne Überprüfung oder Zustimmung durch Menschen? 
		 Es funktioniert durch die
		 <ul style={{ "list-style": "none" }}>
		 <li><b>&nbsp;&nbsp;&nbsp;"Elektronische  Unterschrift" </b>und die weltweite<br/>
		 <b>&nbsp;&nbsp;&nbsp;"Blockchain" - Spiegelung</b> der Transaktionen. 
		 </li>
		 </ul>
		 
		 Wie diese zwei Bausteine von Bitcoin & Co funktionieren können sie hier spielend lernen.<br/>
		 <ol><b>Vorab noch einige Erläuterungen.</b></ol>
		Sie müssen EggHacking nicht spielen, wenn es auch nichts mit bösem Hacking zu tun hat. Sie können einzelne Abschnitte lesen, um sich einen Überblick zu verschaffen,
		einzelne Programmfunktionen nutzen, um das Verständnis zu erhöhen. Sie können am Hacking-Spiel teilnehmen, wenn Sie zu den "Sudoku-Fans" gehören. Aber auch wenn 
		Sie tiefer in die Mathematik elektronischer Signaturen auf Basis elliptischen Kurveneinsteigen möchten, gibt es Abschnitte, z.B. über ECDSA.<br/>
		<br/>
		Auch wenn Bitcoin die Blockchain-Technologie bekannt gemacht hat, so gibt es zwischenzeitlich viele <b>andere wichtige Einsatzgebiete</b>
		<ul>
		<li><a href="https://www.agrarheute.com/management/agribusiness/blockchain-steckt-dahinter-547932" target="_blank"><b>agraheute</b></a> Vermeidung von Lebensmittelskandalen.
		<br/></li>
		<li> <a href="https://agrichain.com/about-the-platform/" target="_blank"><b>AgriChain </b></a>Verbindet Beteiligte der landwirtschaftlichen Lieferkette direkt.
		<br/></li>
		<li> <a href="https://agriledger.io/projects/" target="_blank"><b>AgriLedger</b> </a> Blockchainprojekte z.B. für Farmer auf Haiti uvm.
		<br/></li>
		<li>
		.....</li>
		</ul>		
		 <ol><b>Viel Spaß.</b></ol>
		<h2>Crypto- vs. Fiat-Währung </h2>
		<Link to="CryptoFiat"><img src={Bitcoin} alt="CryptoFiat" style={{"float" : "left", "width" : "10%" , "padding-right" : 10}}></img> </Link>		
		Klicken Sie auf den Bitcoin, wenn sie mit den Begriffen Crypto- vs. Fiat-Währung, Hashing, Signatur, Blockchaining, Nodes usw. noch nicht so vertraut sind.
<br/>
<br/><br/>
		<hr/>
	
		<h2> Was sind EggCoins und was kann man damit machen?</h2>
		<p>Mit Hilfe einer<b> fiktiven "Kryptowährung", den EggCoin's </b>("Eiweiler-Grosswald-Gulden"), können die folgenden Kernelemente von Bitcoin und 
		Co. , die 
		<ul>
		<li><b>"Elektronische Unterschrift"</b> (Signatur) der <b>Überweisungen</b> (Transaktionen), durch die Ausstellerin oder den Aussteller 
		sowie die</li>
		<li><b>"Unverfälschbarkeit"</b> der <b>Kontoauszüge</b> ( Blöcke) durch <b>Verkettung</b> (Blockchain) der Blockprüfziffern (Cryptographic 
		Hashing / sha256)</li>
		</ul>
			spielerisch nachvollzogen werden, indem Sie
		<ul>
		<li>für jedes Konto, dessen <b>Signaturschlüssel</b> Sie kennen,  <b>Transaktionen </b> (Überweisungsaufträge) anlegen können und </li>
		<li>spielerisch das <b>Mining</b> eines neuen EggCoin-Blocks aus den angelegten Aufträgen starten können. </li>
		</ul>
		Damit Sie verstehen was bei den einzelnen Schritten passiert, müssen Sie bei unserern fiktiven EggCoins die Signatur ihrer Aufträge (mit kleinen Rechnereien im großen 1 X 1) selbst erstellen, was bei Bitcoin und Co. 
		ihre "Wallet-App"  erledigt!<br/>
		<ul>
		<li>Um einerseits die zur Signatur notwendigen Rechenschritte so einfach wie die Lösung eines Sudoku's zu halten </li>
		<li>aber andererseits die Kernbestandteile der <b>"Elektronischen Signatur "</b> in einfachster Form zu verwenden<br/></li>
		</ul>
		werden bei unserem <b>fiktiven "Crypto-Coin"</b> alle Kontonummern (<b>Accounts</b>), die öffentlichen
		und geheimen Schlüssel (<b>Public/Private Keys</b>) zur Signatur sowie die Prüfziffern (<b>Hash's</b>) der Buchungen (<b>Transaktionen</b>) 
		und <b>Blöcke</b> nur mit zweistelligen Zahlen ( Zahlen kleiner 100) dargestellt und bei allen Berechnungen wird jeweils nur mit den beiden 
		letzen Ziffern weitergerechnet. (<b>Modulo 100</b>)
		<hr/>
		<br/>
		<ul>
		<li>
  <Link to="Mod100"><img src={Uhr} alt="Modulo" style={{"float" : "left", "width" : "15%" , "padding-right" : 10}}></img> </Link>		

<b>(Modulo) </b>Klicken Sie auf die Uhr, wenn Sie mit dem Begriff Modulo noch nicht so vertraut sind oder speziell erfahren möchten
		warum wir bei unserem EggCoin alle Berechnungen nur mit den beiden kleinsten Ziffern weiterrechnen, wenn das Ergebnis größer als 100 sind.
<br/><br/>
		</li>
		<li style={{clear:"both"}}>
  <Link to="PrfSig"><img src={Prfsig} alt="PrfSig" style={{"float" : "left", "width" : "15%" , "padding-right" : 10}}></img> </Link>		

<b>(Prüfnummer) </b>Klicken Sie auf das Bild und Sie erfahren wie einfach bei Eggcoin das "Hash" als Prüfnummer berechnet wird und wie man prüft, dass die Signatur unter der
		Kenntnis des zum Konto passenden privaten Schlüssels erfolgt ist.
<br/><br/><br/>		
		</li>
		<li>
  <Link to="CreSig"><img src={Schloss} alt="CreSig" style={{"float" : "left", "width" : "10%" , "padding-right" : 10}}></img> </Link>		

<b>(Signatur) </b>Klicken Sie auf das Zahlenschloss, wenn Sie mit dem Begriff Signatur noch nicht so vertraut sind oder speziell erfahren möchten
		wie <b>"Privater Schlüssel </b> und <b>Öffentlicher Schlüssel </b> zusammenhängen.
		</li><br/>
	</ul><br/>
</p>

	<hr/>
	
	<h3>Beispiel einer EggCoin Überweisung</h3>
		Der Versuch durch systematisches Probieren unsere &auml;u&szlig;erst <b>simple</b> elektronische Signatur mit nur <b>zweistelligen Zahlen</b> zu "knacken", 
		um sich von fremden Konten EGGcoins zu "stehlen", zeigt <b>im Umkehrschluss die Unverfälschbarkeit</b> der bei Bitcoin und Co. eingesetzten Signaturen mit sehr großen Zahlen:<br/>
		<ul >
		<li>Bei der Bitcoin Signatur wird <b>Modulo(115.792.089.237.316. 195.423.570.985.008.687.907. 853.269.984.665.640.564.039. 457.584.007.908.834.671.663) </b> 
		gerechnet. <br/>
		(Die Dezimalzahl hat <b>78 Dezimalstellen</b>, ist hexadezimal p = 0xffffffff ffffffff ffffffff ffffffff ffffffff ffffffff fffffffe fffffc2f binär 256 Bit)</li><br/>
		<li>Die Anzahl Sandkörner auf der ganzen Welt schätzt man auf weniger als 9.223.372.036.854.775.808</li><br/>
		<li> Alle Rechner der Welt zusammen könnten in unserem Leben nicht alle Möglichkeiten bei der Suche nach einem passenden geheimen Schlüssel durchprobieren!<br/>
		<i>(Das Durchprobieren aller Möglichkeiten - denken Sie an ein Zahlenschloss am Koffer mit nur 4 Einstellräder dessen Nummer zur Öffnung vergessen wurde. 
		Da hilft nur noch das Durchprobieren der 10.000 Möglichkeiten ("Brute-Force")</i>
		</li>
		</ul>
<hr/>
<br/>
<div><i><b><small>(Prüfnummer/Signatur am Beispiel einer EggCoin Überweisung)</small></b></i></div>
<img src={auftrag0} alt="TA-Auftrag" style={{"float" : "left", "width" : "70%" , "padding" : 10}}></img> 
<p style={{clear:'both'}}>		
	 
	Bei dieser Beispiel -  Transaktion von EggCoins in unserem Spiel wird vom 

	<ul>
	<li><b>Account</b> mit der Nummer <b>3</b> </li>
	<li> <b> 1 EggCoin </b> </li>
	<li> an den <b>Account 13 </b>gesendet.</li>
	</ul>

	Neben den Informationen <b>"Von", "An"</b> und <b>"Wert"</b> mit den Zahlen 3, 13, 1 gibt es zwei weitere Zahlen, 
	<ul>
	<li>die Prüfnummer <b>P-Nr</b> mit dem 	Wert 9 sowie </li>
	<li>die Signatur <b>Sig</b> mit dem Wert 69.</li>
	</ul>
	Zum Nachweis, dass eine Transaktion vom Inhaber des angegebenen Accounts kommt, dient die <b>Signatur </b> ( die elektronische Unterschrift), die der
	Absender der Transaktion mitgeben muss.	<br/>

	<br/>Dazu berechnet er mit der <b>Kontonummer(Account) 3</b> aus den "Netto-Informationen" , den <b> Zahlen 3,13 und 1</b> eine 
	Prüfzahl <b>P-Nr = 9 </b>, 	die er dann mit einer nur ihm bekannten Zahl, dem <b>Privaten Schlüssel</b>, zur Signatur-Zahl<b> Sig = 69</b> verschlüsselt.
</p>
<div><i><b><small>*)<u> Erläuterungen zur Berechnung der P-Nr und der Signatur finden Sie durch anklicken der Abbildungen Lupe/Schloss oben</u></small></b></i></div>
<hr/>
<h3>Beispiel mit mehreren Beteiligten</h3>	
<p>In unserem nachfolgenden Beispiel haben die "Inhaber" der Konten mit der Nummer 3 bzw. der Nummer 19 in ihren "EggCoin Online-Banking"
Programmen, <b>"Wallets" </b> genannt, die Zweigstelle Node1 eingetragen und senden ihre "Überweisungen" an ihn: <br/>
<ul>
<li> Konto 3 beauftragt den Node1 ein egg an das Konto mit der Nummer 13 zu überweisen.</li> 
<li> Konto 19 beauftragt Node1 von seinem Konto 5 eggs an Konto 3 zu überweisen.</li>
</ul>

<ul>
<li> Der Node2 wird von Konto 11 beauftragt 3 egg's an den Acount 9 zu überweisen.</li> 
<li> Account 7 beauftragt schließlich Node2 von seinem Konto 9 egg's an Konto 3 zu überweisen.</li>
</ul>
<ul>
<li> Die Nodes (Zweigstellen) geben die bei ihnen eingegangenen Überweisungsaufträge jeweils an die ihnen bekannten 
"Nachbar-Nodes" weiter. <br/>Dadurch erhalten innerhalb sehr kurzer Zeit alle im Internet aktiven Nodes desselben Coins alle 
weltweit eingegangenen Überweisungsaufträge in einer Art Schneeballsystem.</li>
</ul>
<div><i><b><small><u>Die folgende Abbildung zeigt die einzelnen Schritte. </u></small></b></i></div>
<div><i><b><small><u>Anklicken wechselt in eine dynamische Ansicht der Einzelschritte.</u></small></b></i></div> 
 <p>
<Product data={[state.post[3]]} />
</p>
Nach dem Empfang von Überweisungsaufträgen (Transaktionen) <b>prüft jeder Node zwei Dinge: </b> 
<ul>
<li>1. ob das im Feld "Von" angegebene Konto wirklich vom Sender des Auftrags stammt und </li>
<li>2. ob dieses Konto genügend eggs besitzt, um die Überweisung durchzuführen. </li>
</ul>
</p>
<p>Für die <u><b>1. Prüfung</b></u> müssen die Inhaber der Konten ihrer Überweisung zusätzlich noch die <b>"Elektronische Signatur"</b>, 
eine <b>"Elektronische Unterschrift"</b> mitgeben:
</p>
<ul>
<li> 
	Dazu wird zunächst aus allen Daten der Transaktion mit einem bestimmten Verfahren eine eindeutige Zahl, eine Art Check- oder Prüfnummer, gebildet. 
	(Bei Bitcoin Hash genannt).
	Da das Verfahren zur Bildung der Prüfzahlen veröffentlicht ist, kann jeder andere diese Prüfzahl ebenfalls aus den Daten der Transaktion berechnen.<br/></li>
<br/>
<li>
	Der Absender der Transaktion bildet aus dieser Zahl mit Hilfe seines privaten Schlüssels in eine neue Zahl (bei Bitcoin ein Zahlenpaar), die <b>Signatur </b> der Transaktion.<br/></li>
<br/>
<li>Bei unserem EggCoin ergibt sich der zur Überprüfung der Signatur erforderliche öffentliche Schlüssel aus der Account-Nummer. 
Sie sind identisch. (Bei Bitcoin wird der öffentliche Schlüssel mit der Transaktion versendet und daraus die Account-Nummer berechnet) <br/>
Man kann zur Überprüfung der Signatur die Prüfzahl aus der Überweisung selbst berechnen und mit dem öffentliche Schlüssel überprüfen. 
Bei unserem EggCoin muss die selbst berechnete Prüfzahl mit derjenigen übereinstimmen, die man aus der Signatur und dem öffentlichem Schlüssel 
herleiten kann. Unterscheiden sich die Ergebnisse, dann wurde die Transaktion verfälscht. <br/></li>
</ul>
Für die <u><b>2. Prüfung</b></u> werden <b>verkettete Kontoauszüge/Blöcke</b> zur Ermittlung des Kontostandes genutzt.<br/>
Blöcke werden nach bestimmten Zeitabschnitten als Kontoauszüge für die zwischenzeitlich eingetroffenen und geprüften Aufträge von ganz bestimmten "Zweigstellen/Nodes", 
den <b>Miner</b> erstellt. <br/><br/>Unten sind als Beispiel zwei Blöcke unseres EggCoins abgebildet.<br/><br/> Erhält nun ein Node einen Überweisungsauftrag von Konto 3 an Konto 11 
mit Wert 13 und ist die Signatur in Ordnung, dann prüft der Node, ob Account 3 genügend Coins auf seinem Konto hat:
<ol> 
<li>In <b>Block 0</b> befindet sich ein <b>Zugang von 10 egg</b> an Konto 3 </li>
<li>In <b>Block 1</b> ein <b>Abgang von 1 egg</b> an Konto 13, ein <b>Zugang von 5 egg</b> von Konto 19 und ein <b>Zugang von 9 egg</b> von Konto 7</li>
<li> Also hat Konto 3 einen <b>Kontostand</b> von <b>23 egg vor</b> der gewünschen Überweisung. Also genügend eggs für die Überweisung von 13 eggs.</li>
</ol>
 
Das Verfahren der Blockbildung wird <b>"Mining" </b> und die Nodes, die diese Blöcke bilden werden <b>Miner</b> genannt.<br/>
Zusätzlich müssen die Miner bei der Bildung eines neuen Blocks neben den neuen Transaktionen noch das Hash des vorherigen Blocks (alter Kontoauszug), das aktuelle Datum 
mit der Uhrzeit und eine vom Miner frei wählbare Zahl, das <b>Nonce</b> in den Block eintragen.<b/> (siehe die Blockdarstellung unten)
<ol><div><b><small> Bei Bitcoin muss der Miner den Wert von Nonce solange durch Probieren abändern, bis das Hash des neu erstellten Blocks mit einer vorgegeben Anzahl von Nullen beginnt.
 (siehe Beispiele unter dem Menüpunkt "Hash")
</small></b></div>
</ol>
<div><i><b><small>Bsp. Blöcke nach Mining (Anklicken vergrößert)</small></b></i></div>
<Product data={[state.post[1]]} /> 
<br/>Bei unserem EggCoin verwenden wir als Hash die leicht modifizierte Quersumme aller im Block vorkommenden Ziffern (geändert auf ungerade Zahlen, die nicht durch 5 teilbar sind).

<br/><br/>
<div>
<i><b><small>
 Möchten Sie das fiktive EggCoin-System nur ausprobieren, dann klicken Sie  
   <Link to="eggtraining"> hier. </Link>		

 </small>
</b></i>
</div>
<div>
<i><b><small>
Möchten Sie sich als  "White-Hat-Hacker/Haeckse" (Synonyme für gute männliche/weibliche Hacker im CCC) beteiligen, 
dann klicken Sie 
   <Link to="egghacking"> hier. </Link>		
</small>
</b></i>
<br/>
</div>

   </Main><br/>
  </>
  );
}
