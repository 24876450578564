//opt import sha256 from "crypto-js/sha256";
import React, { useState } from "react";
import { Link } from "react-router-dom";
//opt import Lnk		from "../components/Lnk";
//opt import props from 'prop-types';
import styled from "styled-components";
import { Main } from "../components/styles/Main";
import { Hashs } from "../lib/hashs";
import Eierpalette from "../bilder/Eierpalette.png";
import Breakpoint  from 'react-socks';
 //function quersumme(forceOneDigit) {
 //   var z = forceOneDigit.toString().split('');
 //   for (var i=0, quer=0; i < z.length; quer+=z[i++]-0);
    //if( forceOneDigit && quer > 9) return quer.quersumme(forceOneDigit);
 //   return quer;
//}

 
export default function HashsComponent(props) {
// const funcNav=props.funcNav;
 
  const [hashs, setHashs] = useState(new Hashs());
//opt  const [data, setData] = useState(hashs.data);
//opt  const [hash, setHash] = useState(hashs.hash);

//opt  const [hashxy, setHashxy] = useState(hashs.hashxy);
 //opt const [outputMessages, setOutputMessages] = useState([]);
  const [values, setValues] = useState({
    eingabe: "",
	ein2hex: "",
	base58: "",
    prfqs:"",
    prfnr:"",
	dtasha:"",
	dtashadec:"",
	dtabase58:"",
  });
//opt  const [isLoading, setIsLoading] = useState(false);

 
  const set = (name) => {
//console.log(values);
	
    return ({ target: { value } }) => {
      setValues((oldValues) => ({
        ...oldValues,
        [name]: value,
//opt		["ein2hex"]: hashs.dtaHex(oldValues.eingabe),
//opt		["prfqs"]: hashs.querSumme(oldValues.eingabe)%100,
//opt		["prfnr"]: hashs.pruefNummer(oldValues.eingabe)%100,
//opt		["iban"]:  hashs.iban(oldValues.eingabe),
//opt		["base58"]:  hashs.toBase58(oldValues.eingabe),
//opt		["dtasha"]:  hashs.secHash(oldValues.eingabe),
//opt		["dtashadec"]:hashs.secHashDec(oldValues.eingabe),
//opt		["dtabase58"]:hashs.secToBase58(oldValues.eingabe),
    }));
  };
  }

//0x16 58^2=3364 | 61: 5*58=290 0x122 | A1: 9*58=522 0x20a | 56*58=3248 57*58=3306 57*58 + 54 =3363
// 0x1ceeb25aa9931466dfda599653d827c8fe70e9a20278ebe5744206ed165f54e7
//76949280022739288613800702199389566746553352754411619296370143719059980932905
//var eing = strng.startsWith('0x') && strng.length >2?strng.substring(2, strng.length):decToHex(strng);//this.secHash(strng);
//Thu Oct 06 2022 14:58:33 GMT+02 00 (Mitteleuropäische Sommerzeit) 9 3 10 13 73 9 7 10 17 97 9 11 10 13 73 9 13 10 17 97 9 19 10 21 81
// 38 + 44 + 59 + 61 = 202
//39
//1 7875 3 Mon Jan 09 2023 15:13:46 GMT+0100 (Mitteleuropäische Normalzeit) 3 13 1 9 69
  return (
  
      <>
  <Main>
          <h1>Hash's</h1>
        <hr />
        <p>
		Ein wesentliches Kernelement bei der Signatur von <b>Transaktionen</b> ("Überweisungen") sowie der Verhinderung einer 
		nachträglichen Manipulation der gespeicherten Daten in den verketteten Blöcken ("Kontoauszügen") der <b>Blockchain </b>
		ist das <b>"Kryptografische Hashing"</b>.
		<ol><i>(Am Ende der Seite können Sie im Echteinsatz befindliche Hash's (Secure-Hash-256) sowie einfache Prüsummen, wie die zwei Ziffern an Stelle 3 und 4
		unserer IBAN ausprobieren, teilweise mit Anzeige in verschiedenen Zahlensystemen.)</i></ol>
	
		Dabei nehmen sogenannte <b>Hash-Funktionen</b> beliebige Informationen wie Texte, Kontoauszüge, Bilder usw. entgegen und 
		bilden daraus eine meist sehr große ganze Zahl mit vorgegebener Stellenzahl. So erzeugt z.B. die bei Bitcoin &Co verwendete <b>sha256-Funktion </b> 
		("Secure-Hash-256") bei allen Dateneingaben Zahlen mit 256 Bit im Binärsystem (64 Zeichen im Hexadezimalsystem bzw. zwischen 
		76 und 78 Dezimalzeichen).
        </p>

        <p>
		Das bedeutet, dass mit Hilfe einer Hashfunktion aus beliebigen Daten ein einzigartiger Zahlenwert gebildet wird. Dabei haben Hash-Funktionen
		wichtige Eigenschaften:
		</p>
        <h2>Eigenschaften von Hash's</h2>
        <ul>
          <li>
            <b>Schnelle Berechnung</b><br/>
              Hash-Funktionen sollen effektiv durch Computerprogramme berechenbar sein.
          </li><br/>
          <li>
            <b>Avalanche Effect</b><br/>
            <p>
              Kleinste Änderungen in der Eingabenachricht sollten große und unvorhersehbare Änderungen des Hash-Wertes bewirken
            </p>
          </li>
          <li>
            <b>Deterministisch</b><br/>
            <p>
			  Jedesmal, wenn die gleiche Eingabenachricht vorliegt, muss derselbe Hashwert erzeugt werden.
            </p>
          </li>
          <li>
            <b>Einbahnstraße/"One-Way Function"</b><br/>
            <p>Es sollte so gut wie unmöglich sein aus dem Hash-Wert Rückschlüsse auf die zugrunde liegende 
			Nachricht zu ziehen.
			</p>
          </li>
          <li>
            <b>Kollision Resistenz</b><br/>
            <p>Zwei unterschiedliche Eingangsnachrichten sollten nicht denselben Hash-Wert erzeugen.
			</p>
          </li>
        </ul>
        <h2>Wozu Hash's bzw. Prüfsummen?</h2>
	<p>
	Die Hash-Zahlen sind ein wesentliches Element, um aus den Bitcoin & Co - Transaktionen und den verketteten "Kontoauszügen" , den Blockchains, 
	mittels Verschlüsselung-Verfahren, <b>Signaturen</b> zu erstellen. 
	<br/><br/>	Beispiele sind 
	<ul>
	<li><b>RSA</b> <a href="https://de.wikipedia.org/wiki/RSA-Kryptosystem" target="_blank"  rel="noreferrer">(ähnlich unserem minimalen Signaturverfahren mit Potenzieren Mod100)</a>,</li> 
	<li><b>Schnorr-Signatur, </b>(<a href="https://de.wikipedia.org/wiki/Claus_Peter_Schnorr" target="_blank"  rel="noreferrer">Claus Peter Schnorr, * 4.8.1943 in Vöklingen)</a>)</li> 
	<li><b>Digitaler Signature Algorithmus</b> <a href="https://de.wikipedia.org/wiki/Digital_Signature_Algorithm"  rel="noreferrer" target="_blank"> ( DSA )</a></li>
	<li>Elliptsche Kurven-Signatur <b>ECDSA</b> 
	
	<Link to="../ECDSA">(wird bei Crypto-Coins verwendet) </Link>		

	</li>
	</ul>
	Diese Verfahren erstellen aus der großen Hashzahl einer Nachricht und der großen Zahl des privaten Schlüssels des Erstellers eine oder zwei  Zahlen als <b>"Signatur" </b>,
	die sich mit keiner anderen Zahl als dem privaten Schüssel erstellen läßt/ lassen und nur diese lassen sich mit dem zugehörigen öffentlichen Schlüssel verifizieren.<br/>
	<br/>Bei unserer "Minimalsignatur des EGGCoins" werden dieselben Verfahren verwendet. Allerdings sind sie sehr leicht zu "knacken", da die verwendeten 
	Zahlen unserer Hash-Funktion und unsere Schlüssel nur 2-stellig sind und man damit zur Not alle Möglichkeiten sogar mit Kopfrechnung und ohne Computer durchprobieren kann. 
	<br/>Wir verwenden bei unseren Eggcoins zur Signaturerzeugung <b>Hash's</b> die aus einer leicht <b>angepassten Quersumme</b> aller vorkommenden Zahlenwerte bestehen 
	(bei den Transaktionen die Werte "Von","An" und "Wert", bei den Blöcken alle vorkommenden Ziffern). Die Signatur entsteht, indem wir den Hash mit dem privaten Schlüssel Modulo100 potenzieren, d.h. <b>"verschlüsseln"</b> (damit ist die Signatur auch nur zweistellig).<br/>
	Die Überprüfung (<b>Verifikation</b>) der Signatur besteht aus der <b>Entschlüsselung</b> dieser Signatur, indem sie Modulo100 potenziert wird mit dem öffentlichen Schlüssel, was dann wieder die
	 Prüfnummer /Hash ergeben muss.
	<br/>
	<hr/>
    <h2>Hash'/Prüfnummern ausprobieren</h2>
	Unten können Sie im Formular in das Eingabfeld beliebige Zeichen eingeben und bekommen je nach Eingabe unterschiedliche Informationen angezeigt.
	Wenn Sie sich über die bei Bitcoin & Co. verwendeten unterschiedlichen Zahlensysteme informieren möchten, dann klicken Sie auf das Bild mit der "Eierpalette".
	<br/><br/>
<div><i><b><small>( Eier-Palette 12X12)</small></b></i></div>
<Link to="../zahlensys"><img src={Eierpalette} alt="Zahlensys" style={{"float" : "left", "width" : "50%" , "padding-right" : 10}}></img> </Link>		
{//<Lnk data={[state.lnk[0]]} funcNav={funcNav}/>
}
	 <ul>
	 <li>geben Sie unten <b>DE00100400600100400600 </b> ein, (die IBAN vom "Aktionsbündnis Kastastropenhilfe" mit 00 an Stelle 3 und 4) 
		dann wird im Feld IBAN die Prüfziffer der IBAN <b>65</b> angezeigt<br/>
		oder <b>DE00200400600200400600</b> die IBAN des ZDF Spendenkontos mit der sich die Prüfziffer <b>53</b> zeigt.
		<ol><i> (Oder eine andere IBAN mit DE00 an Stelle 1 bis 4 !Wichtig ist der Anfang mit groß <b>DE</b> gefolgt von zwei Nullen <b>00</b> und insgesamt 22 Stellen)</i></ol></li>
	 <li>geben Sie eine Dezimalzahl (Ziffern 0,1,2..9 eine Stelle weiter links hat 10 fachen Wert) ein, dann sehen Sie im Feld unterhalb die Zahl im hexadezimal-Format
	 (Ziffern 0,1,2,..9,a,b,c,d,e,f - wobei a den Wert 10, b den Wert 11 bis f den Wert 15 hat und eine Stelle weiter links hat 16 fachen Wert	
	 </li>
	</ul>
	 </p>

<p>	

<h2>Riesengroße Zahlen bei Bitcoin und Co.</h2>
	Für die Verschlüsselung bei der Bildung und Überprüfung von Signaturen aus den Hash-Zahlen verwenden Bitcoin & Co. Schlüssel ganzer Zahlen kleiner der Primzahl<br/>
	<br/><b>P=115.792. 089.237. 316.195. 423.570. 985.008. 687.907. 853.269. 984.665. 640.564. 039.457. 584.007. 908.834. 671.663</b> mit 78 Dezimalstellen.<br/>
	<br/>Eine Bitcoin Adresse ist auch eine sehr große Zahl, die im binären Zahlensystem 160 bit (0,1) besitzt. In unserer gewohnten Dezimalschreibweise 
	sind es <br/>
	<br/><b>2 <sup>160</sup> = 1.461. 501.637.330. 902.918.203. 684.832.716. 283.019.655. 932.542.976</b> verschiedene Bitcoin-Adressen.
	Eine Zahl mit 49 Dezimalstellen! 
	<br/>Die Anzahl <b>Sandkörner</b> auf der ganzen Welt schätzt man auf weniger als <b>2<sup> 63</sup> =  9.223.372.036.854.775.808 </b><br/>
	Es gibt somit 1.000.000. 000.000. 000.000. 000.000. 000.000 mal mehr Bitcoin Adressen als Sandkörner auf der Welt!
	<br/><br/>
	Um diese großen Zahlen mit weniger Zeichen darzustellen, kommen bei Bitcoin&Co. andere Zahlensysteme zum Einsatz, wie z.B. das Base58-System.
	Hinweise dazu finden Sie <a href="Zahlensys">hier</a>
	
</p>
{//</Main>
}

	<TodoSection>
	  <Breakpoint medium up>
        <Form>
          <p className='heading'>Verschiedene Prüfziffern (Hashs) ausprobieren</p>
          <fieldset>
            <label htmlFor='eingabe'>
              Eingabe
              <input 
                onChange={set("eingabe")}
                value={values.eingabe}
				size='45'
                name='eingabe'>
               </input>
            </label>

            <label htmlFor='ein2hex'>            
               dez | hexa
               <input
                 onChange={set("ein2hex")}
                 value={hashs.dtaHex(values.eingabe)
				 }
				 size='45'
                 name='ein2hex'
				 disabled="true"
                 >
				</input>
              </label>

            <label htmlFor='base58'>            
               dez | base58
               <input
                 onChange={set("base58")}
				 value={ hashs.toBase58(values.eingabe)}
				 size='45'
                 name='base58'
				 disabled="true"
                 >
				</input>
              </label>

            <label htmlFor='prfqs'>            
              Quersumme
               <input
                 onChange={set("prfqs")}
                 value={hashs.querSumme(values.eingabe)%100
				 }
				 size='2'
                 name='prfqs'
				 disabled="true"
                 >
				</input>
              </label>


           <label htmlFor='prfnr'>            
             Prüfnummer
               <input
                 onChange={set("prnr")}
                 value={hashs.pruefNummer(values.eingabe)%100
				 }
				 size='2'
                 name='prfnr'
				 disabled="true"
				 >
				 </input>
              </label>

          <label htmlFor='iban'>            
             2 IBAN Prüfziffern hinter DE
               <input
                 onChange={set("iban")}
                 value={hashs.iban(values.eingabe)
				 }
				 size='2'
                 name='iban'
				 disabled="true"
				 >
				</input>
              </label>

			  
         <label htmlFor='dtashadec'>sha256(dezimal): </label>
          <textarea
            type='textarea'
            name='dtashadec'
            id='datadec'
            rows='3'
            cols='5'
            value={hashs.secHashDec(values.eingabe)}
            onChange={set("dtashadec")}
          />
			  
         <label htmlFor='dtasha'>sha256: </label>
          <textarea
            type='textarea'
            name='dtasha'
            id='data'
            rows='2'
            cols='5'
            value={hashs.secHash(values.eingabe)}
            onChange={set("dtasha")}
          />
			  
         <label htmlFor='dtabase58'>(sha256) Base58: </label>
          <textarea
            type='textarea'
            name='dtabase58'
            id='datadecb'
            rows='3'
            cols='5'
            value={hashs.secToBase58(values.eingabe)}
            onChange={set("dtabase58")}
          />
		  
 
          </fieldset>
        </Form>

</Breakpoint>

	  <Breakpoint small down>
        <Form>
          <p className='heading'>Verschiedene Prüfziffern (Hashs) ausprobieren</p>
          <fieldset>
            <label htmlFor='eingabe'>
              Eingabe
              <input 
                onChange={set("eingabe")}
                value={values.eingabe}
				size='24'
                name='eingabe'>
               </input>
            </label>

            <label htmlFor='ein2hex'>            
               dez | hexa
               <input
                 onChange={set("ein2hex")}
                 value={hashs.dtaHex(values.eingabe)
				 }
				 size='24'
                 name='ein2hex'
				 disabled="true"
                 >
				</input>
              </label>

            <label htmlFor='base58'>            
               dez | base58
               <input
                 onChange={set("base58")}
				 value={ hashs.toBase58(values.eingabe)}
				 size='24'
                 name='base58'
				 disabled="true"
                 >
				</input>
              </label>

            <label htmlFor='prfqs'>            
              Quersumme
               <input
                 onChange={set("prfqs")}
                 value={hashs.querSumme(values.eingabe)%100
				 }
				 size='2'
                 name='prfqs'
				 disabled="true"
                 >
				</input>
              </label>


           <label htmlFor='prfnr'>            
             Prüfnummer
               <input
                 onChange={set("prnr")}
                 value={hashs.pruefNummer(values.eingabe)%100
				 }
				 size='2'
                 name='prfnr'
				 disabled="true"
				 >
				 </input>
              </label>

          <label htmlFor='iban'>            
             2 IBAN Prüfziffern hinter DE
               <input
                 onChange={set("iban")}
                 value={hashs.iban(values.eingabe)
				 }
				 size='2'
                 name='iban'
				 disabled="true"
				 >
				</input>
              </label>

			  
         <label htmlFor='dtashadec'>sha256(dezimal): </label>
          <textarea
            type='textarea'
            name='dtashadec'
            id='datadec'
            rows='3'
            cols='5'
            value={hashs.secHashDec(values.eingabe)}
            onChange={set("dtashadec")}
          />
			  
         <label htmlFor='dtasha'>sha256: </label>
          <textarea
            type='textarea'
            name='dtasha'
            id='data'
            rows='2'
            cols='5'
            value={hashs.secHash(values.eingabe)}
            onChange={set("dtasha")}
          />
			  
         <label htmlFor='dtabase58'>(sha256) Base58: </label>
          <textarea
            type='textarea'
            name='dtabase58'
            id='datadecb'
            rows='3'
            cols='5'
            value={hashs.secToBase58(values.eingabe)}
            onChange={set("dtabase58")}
          />
		  
 
          </fieldset>
        </Form>

</Breakpoint>

      </TodoSection>
	  </Main>
      <hr />

    </>
  );
}

const TodoSection = styled.section`
display:flex;
justify-content: center;

  p {
    margin: auto;
  }

  .heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: #555;
    text-decoration: underline;
    margin-bottom: 0.25rem;
  }
`;

const Form = styled.form`

  padding: 5px;
  border-radius: 10px;
  border: 1px solid #bbb;
  fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin: auto;
  }

  label {
    display: flex;
    justify-content: space-between;
  }

  input {
	color: #010101;
   font-size: 0.8rem;	
   font-weight: 600;	
  }
  input:disabled {
	color: #010101;
   font-size: 0.8rem;	
   font-weight: 600;	
  }	  
  
`;