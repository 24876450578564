import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Breakpoint , BreakpointProvider, setDefaultBreakpoints  } from 'react-socks';

export 

const SingleBlockCard = styled.div`
  .schmal {
	width: 90%; 
  }
  position: relative;
  padding: 2px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px #00000022;
  background-color: ${({ valid }) => (valid ? "#dfffda" : "#ffdada")};
  form {
/* AppleSafari - flex: https://stackoverflow.com/questions/17447807 */

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
   flex-direction: column;
  }
  fieldset {
    border: none;
    height: 100%;

   display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
   flex-direction: column;
 
    //padding: 0;
  }
  label {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
   flex-direction: column;

  }
  input {

	color: #020202;	  
    border-radius: 3px;
    border: 1px solid #333;
  }
  textarea {
    border-radius: 3px;
    border: 1px solid #333;
  }
  span {
    background-color: #eee;
    padding: 1px 3px;
    border-radius: 3px;
    border: 1px solid black;
  }
  .transactions {
    background-color: #ffffffaa;
    border: 1px solid black;
    border-radius: 0.25rem;
    height: 100%;
    padding: 0.5rem;
  }
  
  .single-transaction {
 display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  
	flex-basis: 4rem;  
   justify-content: space-evenly;

    gap: 0.15rem;
  }
`;


const SingleBlockCardsd = styled.div`
/* flex: https://stackoverflow.com/questions/17447807 */
  position: relative;
  padding: 3px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px #00000022;
  background-color: ${({ valid }) => (valid ? "#dfffda" : "#ffdada")};


  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
    flex-direction: column;
 



  form {
  
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
    flex-direction: column;
  }
 
  fieldset {
    border: none;
    height: 50%
	
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
   
    flex-direction: column;
    //padding: 0;
  
  }
  
  label {

    border: none;
    height: 50%
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
   
    flex-direction: column;
	font-size:13px;
	
  }
  input {
	font-weight: 600;
	color: #020202;
    border-radius: 3px;
    border: 1px solid #333;
		max-width: 78vw;
  }
  
  input:disabled {
    font-size:13px;
	font-weight: 600;
	color: #020202;	  
    border: 1px solid #333;
  }

  textarea {
    border-radius: 3px;
    border: 1px solid #333;
  }
  
  span {
    background-color: #eee;
    padding: 1px 3px;
    border-radius: 3px;
    border: 1px solid black;
  }
  
 p {
 font-size: 12px;
 }
 
  .transactions {
	  
 display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column; 
   flex-direction: column;

	max-width: 78vw;
    background-color: #ffffffaa;
    border: 1px solid black;
    border-radius: 0.25rem;

	
/*flex-basis: 1em;
justify-content:left;*/
  }
  
  .schmal {
	width: 3em; 
  }
  
  .single-transaction {
	  
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

   -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

	flex-basis: 3rem;
 /*   gap: 1rem;*/
  justify-content: space-evenly;



	}
`;



const MineButton = styled.button`
  color: ${({ isLoading }) => (isLoading ? "#aaa" : "#333")};
  transform: ${({ isLoading }) => (isLoading ? "scale(.9)" : "")};
  width: ${({ isLoading }) => (isLoading ? "40px" : "60px")};
  aspect-ratio: ${({ isLoading }) => (isLoading ? "1" : "4")};
  border-radius: ${({ isLoading }) => (isLoading ? "100%" : "3px")};
  border: 1px solid #333;
  transition: 0.1s;
`;

export default function BlockchainBlock({
  genesisBlock,
  nonceChange,
  dataChange,
  setChain,
  blockchain,
  block,
}) {
  const [nonce, setNonce] = useState(block.nonce);
  const [data, setData] = useState(block.data);
  const [isLoading, setIsLoading] = useState(false);

  const handleNonceChange = (event) => {
    setNonce(event.target.value);
    nonceChange(block, data);
  };
  const handleDataChange = (event) => {
    setData(event.target.value);
    dataChange(block, data);
  };

  const handleMine = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      let newChain = [...blockchain.mineBlock(block, true)];
      setIsLoading(false);
      setChain(newChain);
    }, 500);
  };

  const handleUpdateTransactionValue = (e) => {
    //get index from custom attribute
    let transactionIndex = parseInt(e.target.attributes.index.value);
    let key = e.target.name;
    let newChain = [
      ...blockchain.updateBlockTransactions(
        block.index,
        transactionIndex,
        key,
        e.target.value
      ),
    ];

    setChain(newChain);
  };
//weli 2023.02.12 alle Eingabefelder gesperrt
  return (
  <>
  <Breakpoint small down >
   <div  style={{width:"100%"}}>
    <SingleBlockCardsd valid={!block.error} style={{width:"100%"}}>
      <form className='abc'>
        <fieldset>
          <label htmlFor='block'>Block:</label>
          <input
           	size='3'
            name='block'
            id='block'
            value={block.index}
            disabled={true}
          />
          <label htmlFor='nonce'>Nonce:</label>
          <input disabled={true}
            size='3'
            id='nonce'
            name='nonce'
            value={nonce}
            onChange={handleNonceChange}
          />
  	       <label htmlFor='tS'>Datum/Zeit:</label>
		   <input
             id='tS'
             name='tS'
             value={block.tS}
			 size='28'
            disabled={true}
            />
	  
          <label className='transactions-label' htmlFor='data'>
            Data:
          </label>

          { (
            <div className='transactions'>
              <>
                {block.transactions.length >= 1 &&
                  block.transactions.map((transaction, index) => (
                    <div
                      key={`block: ${block.index} - transaction: ${index + 1}`}
                      className='single-transaction'>
                      <p>{index + 1}.</p>
                      <label>
                        Von
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          onChange={handleUpdateTransactionValue}
                          name='from'
                          value={transaction.from}
                        />
                      </label>
                      <label>
                        An{" "}
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='to'
                          value={transaction.to}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>
                      <label>
                        Wert
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='amount'
                          value={transaction.amount}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>
                      <label>
                        PNr{" "}
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='prfnr'
                          value={transaction.prfnr}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>
                      <label>
                        Sig{" "}
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='sig'
                          value={transaction.sig}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>




                    </div>
                  ))}
              </>
            </div>
          )}
          <p>
            Vorg. Hash: <span>{block.prevHash}</span>
          </p>
          <p>
            Hash: <span>{block.hash}</span>
          </p>
        </fieldset>
		{ //weli 20230213
//        <MineButton isLoading={isLoading} onClick={handleMine}>
//          {" "}
//          Mine
//        </MineButton>
		}
      </form>
    </SingleBlockCardsd>
 </div>	
</Breakpoint>

<Breakpoint medium up>
  <div  >
    <SingleBlockCard valid={!block.error}>
      <form>
        <fieldset>
          <label htmlFor='block'>Block:</label>
          <input
           	size='3'
            name='block'
            id='block'
            value={block.index}
            disabled={true}
          />
          <label htmlFor='nonce'>Nonce:</label>
          <input disabled={true}
            size='3'
            id='nonce'
            name='nonce'
            value={nonce}
            onChange={handleNonceChange}
          />
  	       <label htmlFor='tS'>Datum/Zeit:</label>
		   <input
             id='tS'
             name='tS'
             value={block.tS}
			 size='28'
            disabled={true}
            />
	  
          <label className='transactions-label' htmlFor='data'>
            Data:
          </label>

          { (
            <div className='transactions'>
              <>
                {block.transactions.length >= 1 &&
                  block.transactions.map((transaction, index) => (
                    <div
                      key={`block: ${block.index} - transaction: ${index + 1}`}
                      className='single-transaction'>
                      <p>{index + 1}.</p>
                      <label>
                        Von
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          onChange={handleUpdateTransactionValue}
                          name='from'
                          value={transaction.from}
                        />
                      </label>
                      <label>
                        An{" "}
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='to'
                          value={transaction.to}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>
                      <label>
                        Wert
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='amount'
                          value={transaction.amount}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>
                      <label>
                        PNr{" "}
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='prfnr'
                          value={transaction.prfnr}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>
                      <label>
                        Sig{" "}
                        <input 
						className='schmal'
						disabled={true}
						maxLength={2}
						size={2}
                          index={index}
                          name='sig'
                          value={transaction.sig}
                          onChange={handleUpdateTransactionValue}
                        />
                      </label>




                    </div>
                  ))}
              </>
            </div>
          )}
          <p>
            Vorg. Hash: <span>{block.prevHash}</span>
          </p>
          <p>
            Hash: <span>{block.hash}</span>
          </p>
        </fieldset>
		{ //weli 20230213
//        <MineButton isLoading={isLoading} onClick={handleMine}>
//          {" "}
//          Mine
//        </MineButton>
		}
      </form>
    </SingleBlockCard>
</div>	
</Breakpoint>

</>	
  );
}
