import React from "react";
import Hashs from "../components/Hashs";
import { Main } from "../components/styles/Main";
import props from 'prop-types';

export default function Hashxy(props) {
	//console.log(props);
	props.funcNav(false);
  return (
    <>

    <Hashs />
    </>
  );
}
