import React from "react";
import Zahlensys from "../components/Zahlensys";
import { Main } from "../components/styles/Main";
import props from 'prop-types';

export default function Zahlensysxy(props) {
	//console.log(props);
	props.funcNav(false);
  return (
    <>

    <Zahlensys/>
    </>
  );
}
