import React, { Component } from "react";

import "./Modal.css";

class Modal extends Component {

	
  render() {
    console.log(this.props.show);
    return (
      <React.Fragment>
        {this.props.show && (
          <div className="modall">
          
			<img
				src={this.props.name}
				height={this.props.h}
				width={this.props.w}
				style={this.props.stl}

				onClick={this.props.onHide}
				alt="no image"
           />
		   
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Modal;

