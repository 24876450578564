import BlockchainComponent from "../components/Blockchain";
import props from 'prop-types';
import { Main } from "../components/styles/Main";
import Product from "../components/Product/Product";
import Block0   from "../bilder/block0.jpg";
import Block01  from "../bilder/block01.jpg";
import Block012 from "../bilder/block012.png";
import netz   from "../bilder/netz.gif";
import Netz13 from "../bilder/netz13.png";
import netzv   from "../bilder/netzv.png"; 

export default function EggBeispiel(props) {
  const funcNav=props.funcNav;	
  const state={
    post: [
      { id: 1, name: Block0, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
      { id: 2, name: Block01, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
      { id: 3, name: Block012, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
	  { id: 4, name: Netz13, name2: netz,
		w:"95%",h:"95%",stl:{align:"left",clear:"both"},
		mw:"95%",mh:"95%", mstl:{clear:'both'}},		
	  { id: 5, name: netz, name2: undefined,
		w:"15%",h:"15%",stl:{float:"left"},
		mw:"75%",mh:"75%", mstl:{clear:'both'}},		
    ]
  };	
  return (
    <>
      <Main>
        <h1>EggBeispiel</h1>
<h2> 4 Transaktionen von 4 Accounts bei 2 Nodes mit EggCoins</h2>
 <p> oder in unserer Sprache <b> 4 Überweisungen von 4 Konten bei 2 Zweigstellen mit EggCoins</b></p>
<hr />
 <Product data={[state.post[3]]} />

<p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<i><b><u>Klick auf das Bild wechselt zwischen dem Ergebnis nach den Überweisungen und einer Schleife mit den Einzelschritten</u> </b></i><br/></p>
<p>In unserem nachfolgenden Beispiel haben die "Inhaber" der Konten mit der Nummer 3 bzw. der Nummer 19 in ihren "EggCoin Online-Banking"
Programmen, <b>"Wallets" </b> genannt, die Zweigstelle Node1 eingetragen und senden ihre "Überweisungen" an ihn: <br/>
<ul>
<li> Konto 3 beauftragt den Node1 ein egg an das Konto mit der Nummer 13 zu überweisen.</li> 
<li> Konto 19 beauftragt Node1 von seinem Konto 5 eggs an Konto 3 zu überweisen.</li>
</ul>

<ul>
<li> Der Node2 wird von Konto 11 beauftragt 3 egg's an den Acount 9 zu überweisen.</li> 
<li> Account 7 beauftragt schließlich Node2 von seinem Konto 9 egg's an Konto 3 zu überweisen.</li>
</ul>
<ul>
<li> Die Nodes (Zweigstellen) geben die bei ihnen eingegangenen Überweisundaufträge jeweils an die ihnen bekannten 
"Nachbar-Nodes" weiter. <br/>Dadurch erhalten innerhalb sehr kurzer Zeit alle im Internet aktiven Nodes desselben Coins alle 
weltweit eingegangenen Überweisungsaufträge in einer Art Schneeballsystem.</li>
</ul>

 <p> 
 Die folgende Abbildung zeigt die einzelnen Schritte nacheinander.<br/>
</p>

 <p>
 <Product data={[state.post[4]]} /> <br/><br/><br/>
	 {//<img height="75%" width="75%" src={netz}  alt='tanetz' />
	 }
</p>
</p>
<p><br/> <i><b><u>Prozess in Einzelschritten</u></b></i> (Klick vergrößert)</p>
<hr/>
<h2>Auftrags-Überprüfung durch die Nodes</h2>
<p> Jeder Node überprüft bei allen von den Wallets oder seinen "benachbarten Nodes" gesendeten Überweisungsaufträgen zwei Dinge: 
<ul>
<li><b>a)</b> ob das im Feld "Von" angegebene Konto vom Sender des Auftrags stammt und </li>
<li><b>b)</b> ob dieses Konto genügend eggs besitzt, um die Überweisung durchzuführen. </li>
</ul><br/>
</p>
<p><b>a):</b>&nbsp;Für die Prüfung, dass der Auftrag wirklich vom angegebenen Von-Konto gesendet wurde und nicht von einem Betrüger, müssen die Inhaber der Konten bei ihren Überweisungen im Feld <b>Sig </b>
zusätzlich noch eine <b>"Signatur"</b>, eine <b>"Elektronische Unterschrift"</b> mitgeben. Dazu hat jedes Konto eine nur dem Kontoinhaber bekannte Zahl, den  
<b> privaten oder geheimen Schlüssel</b>, eine Art <b>PIN</b>. Mit diesem geheimen Schlüssel wird mit den Informationen des Überweisungsauftrages eine bestimmte 
Zahl, die Signatur der Transaktion <b>Sig </b> berechnet:</p>
<p>Mit den Zahlen <b>"Von-Kontonummer","An-Kontonummer"</b>und<b>"Wert"</b> wird 
<ul>
<li> Eine <b>Prüfzahl</b> (im Crypto-Coin Umfeld Hash-Zahl genannt) nach einem allen bekannten Verfahren berechnet, wie z.B. die beiden ersten Ziffern der IBAN eine
Prüfzahl der Bankleitzahl und der Kontonummer darstellen.<br/>Bei unserem EggCoin werden alle Ziffer der Zahlen zur "Quersumme" addiert und daraus durch eine kleine Anpassung die Prüfnummer <b> PN </b>berechbet.</li>
<li> Mit der Prüfzahl und der <b>geheimen Schlüssel-Zahl (PIN)</b> wird nach einer ebenfalls allen bekannten Rechenvorschrift die <b>Signatur-Zahl Sig </b> berechnet und der Überweisung hinzu gefügt</li>
<li>Bei unserem hypothetischen EggCoin wird dazu die Prüfzahl <b>Pr </b> so oft mit sich selbst multipliziert wie der geheime Schlüssel <b>PIN</b> angibt: <b>Sig=Pr<sup>PIN</sup></b><br/>
<b>!!Ganz wichtig ist dabei, dass bei allen Berechnungen immer nur mit den beiden letzten Stellen (Einer- und Zehnerwert) weitergerechnet wird !!! (Modulo 100)</b><br/></li>
<li>!!!!!!!!!!!!!!!!!!!!</li>
<li> Mit der Kontonummer kann dann jeder die verschlüsselte Prüfzahl wieder entschlüsseln und prüfen, ob sie mit der selbst berechneten Prüfzahl übereinstimmt.</li>
</ul>
Stimmt die entschlüsselte Prüfzahl nicht mit der selbst ermittelten Prüfzahl überein, dann wurde <b>"gemogelt".</b>
</p>
<h2> Prüfziffer Berechnung</h2>

<p>
Bei unserem EGGCoin verwenden wir zur Demonstration später eine ganz einfache Prüfzahl - die <b>Summe</b> der Zahlen 
Von, An und Wert und ein ganz einfaches System zur Ver- und Endschlüsselung.    
</p>
		
        <p>
        </p>
        <p>
        </p>
        <h2>Adding Blocks to the Chain</h2>
        <p>
         </p>
        <p>
        </p>
        <p>
        </p>
        <img
          style={{ width: "500px", height: "300px" }}
          src='/miners.png'
          alt='Mining Diagram'></img>
        <h2>How Blockchains Avoid Tampering and Maintain Integrity</h2>
        <p>
        </p>
        <p>
        </p>
        <p>
          There are effectively <strong>three</strong>{" "}
          ways we can work to maintain the blockchain with the inherent
          properties of the blockchain.
          <p>
            <strong>Check the validity of the Hash</strong>
            <br />
            We can always rehash the block using its contents to check its
          </p>
          <p>
            <strong>Check the validity of the Chain</strong>
            <br />
            If you've changed data in one block and recalculated its hash, that
            
          </p>
        </p>
        <h2>Simulate adding transactions to a blockchain</h2>
        <p>
          Below, erates
          a valid hash will be shown before the block appears on the chain
          below.
        </p>
      </Main>
    </>
  );
}
