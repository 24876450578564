import BlockchainComponent from "../components/Blockchain";
import { Link } from "react-router-dom";
//import props from 'prop-types';
import { Main } from "../components/styles/Main";
import Product from "../components/Product/Product";
//opt import Lnk		from "../components/Lnk";
import Block0   from "../bilder/block0.jpg";
import Block01  from "../bilder/block01.jpg";
import Block012 from "../bilder/block012.png";
import netz   from "../bilder/netz.gif";
import Netz13 from "../bilder/netz13.png";
//opt import netzv   from "../bilder/netzv.png";
import Mod10	from "../bilder/mod10.png";
import Uhr		from "../bilder/uhr.png";
import Bitcoin		from "../bilder/bitcoin.png";
import Prfsig	from "../bilder/prf.jpg";
import Schloss	from "../bilder/schloss.png";
import auftrag0 from "../bilder/auftrag0.png";

export default function EggHacking() {
 // const funcNav=props.funcNav;
  const state={
	lnk: [ 	{id:1, imgUrl: Uhr,w:"10%",h:"10%",		stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "Mod100"},
			{id:2, imgUrl: Bitcoin,w:"10%",h:"10%",	stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "CryptoFiat"},
			{id:3, imgUrl: Prfsig,w:"10%",h:"15%",  stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: "PrfSig"},
			{id:4, imgUrl: Schloss, w:"19%",h:"10%", stl:{float:"left",padding:10},mw:"100%",mh:"100vh", lnkUrl: "CreSig"},	  
			{id:5, imgUrl: auftrag0, w:"75%",h:"75%",stl:{float:"left"},mw:"100%",mh:"100vh", lnkUrl: ""}],	  
	  
 
    post: [
      { id: 1, name: Block0, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
      { id: 2, name: Block01, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
      { id: 3, name: Block012, name2: undefined,
		w:"30%",h:"30%",stl:{float:"left"},
		mw:"60%",mh:"60%", mstl:{float:"left"}},
	  { id: 4, name: Netz13, name2: netz,
		w:"95%",h:"95%",stl:{align:"left",clear:"both"},
		mw:"95%",mh:"95%", mstl:{clear:'both'}},		
	  { id: 5, name: netz, name2: undefined,
		w:"15%",h:"15%",stl:{float:"left"},
		mw:"75%",mh:"75%", mstl:{clear:'both'}},
	  { id: 6, name: Mod10, name2: Mod10,
		w:"20%",h:"20%",stl:{"float" : "left", "width" : "20%" , "padding-right" : 10},
		mw:"75%",mh:"75%", mstl:{clear:'both'}}
    ]
  };
  return (
    <>
      <Main>
        <h1>EggHacking</h1>
        <hr />
<h2> !! Beim Verlassen dieser Spiele-Seite wird nichts gespeichert !!</h2>
<p> Nach dem Laden der Seite beginnt das Spiel immer wieder im <b>Grundzustand mit 10 Eggs</b> auf ihrem <b>Account 3</b>. (Siehe unten die Anzeige des <b>Start-Blocks</b>). 
<br/>
<div><i><b><small>(<b>Erläuterungen Egg Signatur</b>)</small></b></i></div>
<Link to="../CreSig"><img src={Schloss} alt="CreSig" style={{"float" : "left", "width" : "19%" , "padding-right" : 20}}></img> </Link>		
<br/>D.h. Sie können zunächst den Umgang mit der Eingabe gültiger Transaktionen von ihrem Konto 3 auf die anderen bestehenden Accounts 
(Konto 7, Konto 11, Konto 13 und Konto 19) üben, so dass Transaktionen für die nächste Blockbildung anstehen, indem Sie 
<br/>
<ul style={{"padding-left" : 2}}>

<li> 
mit ihrem <b>geheimen Schlüssel 7 (bei Kto 3) </b> zur angezeigten Prüfnummer ihrer Transaktion  die passende Signatur Sig berechnen und eingeben:</li>
<br/><li><b>Sig=P-Nr&otimes;P-Nr&otimes;P-Nr&otimes;P-Nr&otimes;P-Nr&otimes;P-Nr&otimes;P-Nr</b> <br/>
	<ol> <i>(&otimes; bedeutet Multiplikation Modulo 100, nur mit den zwei kleinsten Dezimalstellen weiterrechnen und <br/>
nutzen Sie zur Modulo 100 Potenzierung unten den Rechner <b> Potenzieren Modulo100 )</b></i></ol>
</li>

</ul>
und dann einen neuen Block minen.<br/>
Die schwierige Aufgabe, das "Hacking" besteht nun darin <i>in "betrügerischer Weise"</i> Eggs von den fremden Accounts auf ihren Account 3 zu
überweisen, indem Sie die privaten Schlüssel dieser Konten durch probieren und 
systematische Überlegungen herausfinden.
<br/><br/>
(<i><b> Bild anklicken vergrößert.</b></i>)
</p>
<Product data={[state.post[5]]} />
<p style={{clear: "both"}}>
<i><u><b>Sudoku Hinweis:</b></u>
<br/>
<font size="3">
Es gilt: &nbsp;&nbsp;<b>a(<sup>3</sup>)<sup><sup>7</sup></sup> | mod10 = a<sup>21</sup> = a </b>
<br/>Was ist  &nbsp;
<b>a<sup>20</sup> &nbsp;&nbsp;| mod100 = ?,a<sup>21</sup>, a<sup>41</sup>,..,a<sup>481</sup>,..</b><br/>
wenn a ungerade und nicht durch 5 teilbar (3,7,9,11,13,..)? <br/>
nutzen Sie unten "Potenzieren Modulo100"

</font>
</i>

</p>
<p style={{"clear": "both"}} >
<font size="4">
	<b>Viel Glück beim EGGCoin-Hacking-Sudoku und <br/>
Auf geht's - Sieg mit mehr als 33 Eggs auf Konto 3</b>
</font>	
</p>
	</Main>
	<BlockchainComponent />
    </>
  );
}
