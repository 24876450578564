import React from "react";
//opt import { Link } from "react-router-dom";
//opt import props from 'prop-types';
//opt import Block from "../components/Block";
import { Main } from "../components/styles/Main";
//opt import Product	from "../components/Product/Product";
//opt import Lnk		from "../components/Lnk";

import kompass  from "../bilder/kompass.png";
import uhr      from "../bilder/uhr.png";
import kalender from "../bilder/kalender.jpg";
//opt import Back     from "../bilder/back.png";

export default function Mod100(props) { 
//  const funcNav=props.funcNav;
//  	props.funcNav(false);

  //console.log(props);  
  //console.log(window.location);
  return (
  
    <>
      <Main>
        <h1>Modulo</h1>
        <hr />
	<p>
	  <img object-fit="scale-down" width="15%" alt="Kompass" style={{}} src={kompass} />
	  &nbsp;Viele Dinge im täglichen Leben wiederholen sich immer wieder. Ein Winkel von 360 Grad wird wieder
	  als Winkel von Null Grad angesehen. Alle 7 Tage wiederholen sich dieselben Wochentage. Nach <b>364 = 350 + 14 = 50*7+2*7 </b> 
	  Tagen ist wieder der gleiche Wochentag. Nach 365 Tagen sind wir einen Wochentag weiter. Nach 24 Stunden hat man 
	  wieder dieselbe Uhrzeit.<br/><br/>
	  <img object-fit="scale-down" alt="Kalender" width="10%" style={{float:"left",clear:"left"}} src={kalender} />	
	  <img object-fit="scale-down" alt="Uhr" width="15%" style={{float: "right",clear:'right'}} src={uhr} />	
	  </p>
	  <p>&nbsp;</p>


	  <p>&nbsp;</p><p> &nbsp;<br/><br/></p>
      <hr />
	  Bei den betrachteten, beschränkten Zahlenbereichen, wie bei den Wochentagen von <b>1 bis 7</b>, den Stunden <b>0-24</b> bei der Uhrzeit und <b>Winkel</b> zwischen 
	  <b> 0 und 360 Grad</b> wiederholen sich Zahlen. <b>25</b> Uhr ist <b>1</b> Uhr, <b>361</b> Grad ist <b>1</b> Grad. Egal wie der erste Wochentag heißt, nach 7 Tagen hat man denselben Wochentag. Stellt man 
	  den Wecker um 22 Uhr 16 Stunden weiter, dann haben wir nicht 22 + 16 = 38 Uhr sondern wir beginnen  um 24 Uhr wieder mit
	  Null Uhr, d.h. wir ziehen beim Überschreiten von 24 wieder 24 ab und kommen bei 38 - 24 = 14 Uhr an. Zeigt der Kompass 270
	  Grad an und drehen uns dann um 100 Grad nach links, dann zeigt der Kompass nicht 270 + 100 = 370 Grad an sondern 370 - 360 = 10 Grad.<br/>
	  wir schreiben:
	  <ul>
	  <li> <b>( &nbsp;&nbsp;1 + &nbsp;7 &nbsp;)</b>&nbsp; &nbsp;&nbsp; Modulo 7 &nbsp;<b>=</b> &nbsp;&nbsp;<b>8</b> Modulo 7 &nbsp;&nbsp;<b> = 1</b>	Modulo 7 <br/> </li>
	  <li> <b>( 22 + &nbsp;16&nbsp; )</b> &nbsp;Modulo 24 <b>= 38</b> Modulo 24 <b>= 14</b> Modulo 24 <br/> </li>
	  <li> <b>(270 + 100 )</b> Modulo 360 <b>= 370</b> Modulo 360 <b>= 10</b> Modulo 360	 <br/> </li>
		
	  </ul> 
	  <p>
	  Beschränkt man sich auf den Zahlenbereich von Null bis 9, dann rechnen wir <b>"Modulo 10"</b>.<br/>
	  Bei unserer hypothetisch Kryptowährung dem <b>EggCoin</b> beschränken wir uns zum einfachen nachrechnen immer auf den Zahlenbereich von Null bis 100, d.h.
	  wir rechnen <b>"Modulo 100"</b>. <br/>
	  </p>

	<p>
	<br/><u><b>Beispielrechnungen: </b></u>
	<ul>
	<li><u>Modulo 10</u><br/>
	<b>7 &oplus; 4 = [11 Modulo 10]=1 </b><br/>
	<i>(hier verwenden wir statt + zur Verdeutlichung das &oplus; Zeichen für [(7 + 4) Modulo 10] = [11 Modulo 10] =<b> 1 </b>!letzte Ziffer!)</i> <br/>
	<br/>
	<b>4 &otimes; 4 = [16 Modulo 10]=6</b><br/>
	<i>( hier verwenden wir statt * das &otimes; Zeichen für das Rechnen Modulo 10)</i>  <br/><br/>
	</li>
	<li>
	<u>Modulo 100</u><br/>
	<b>67 &oplus; 40 = [107 Modulo 100]=7</b> <br/>
	<i>(hier verwenden wir statt + zur Verdeutlichung das &oplus; Zeichen für [(67 + 40) Modulo 100] = [107 Modulo 100] = <b>7</b> !Zahl aus den beiden letzten Ziffern!)</i> <br/>
	<br/>
	<b>11 &otimes; 11  = [121 Modulo 100]=21</b><br/>
	<i>( hier verwenden wir statt * das &otimes; Zeichen für das Rechnen Modulo 100)</i>  <br/><br/>
	</li>
	</ul>
	ab hier verwenden wir dann aber bei unserem <b>EggCoin</b> wieder die Zeichen + und * Zeichen immer für die <b>Rechnung Modulo 100</b> und rechnen 
	nur mit den Einser- und Zehner-Ziffern der Ergebnisse weiter!( mit den beiden letzten Ziffern der Ergebnisse)
	</p>
	{//<Link to=".."><img src={Back} alt="zurück" style={{"float" : "left", "width" : "10%" , "padding-right" : 10}}></img> </Link>		
	}<br/><br/>	
	</Main>

    </>
  );
}
