import sha256 from "crypto-js/sha256";
import { hexToDec, decToHex } from "hex2dec";

export class Hashs {
  constructor( data, hash = 0) {
    this.timeStamp = Date.now();
    this.data = data;
    this.hash = hash;
    this.error = false;
    this.hashxy = [];
  }

convertBase(value,from_base) {
  var range58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'.split('');
  var rangefb = '0123456789abcdef'.split(''); 
  var to_base= 58;
  var from_range = rangefb.slice(0, from_base);
  var to_range   = range58.slice(0, 58);
   var dec_value = value.split('').reverse().reduce(function (carry, digit, index) {
    if (from_range.indexOf(digit) === -1) throw new Error('Invalid digit `'+digit+'` for base '+from_base+'.');
    return carry += from_range.indexOf(digit) * (Math.pow(from_base, index));
  }, 0);
  var new_value = '';
  while (dec_value > 0) {
    new_value = to_range[dec_value % to_base] + new_value;
    dec_value = (dec_value - (dec_value % to_base)) / to_base;
  }
  return new_value || '0';
}

  recalculateHash() {
    this.hash = sha256(
      this.data
    ).toString();
   
     return sha256(
      this.data
     ).toString();
  }
 
  qS(forceOneDigit) {
    var z = forceOneDigit.toString().split('');
    for (var i=0, quer=0; i < z.length; quer+=z[i++]-0);
    return quer%100;
  }
  
  dtaHex(dta) {
	  dta=dta.toString().replace(/[^A-Za-z0-9]/g, '');
	  if(dta.toString().startsWith('0x')) {
		  return hexToDec(dta);
	  }
	  return decToHex(dta.toString());
  }
  
  querSumme(data) {
	 if(data.toString().startsWith('0x')) { 
	 	return this.qS(hexToDec(data.toString().replace(/[^A-Za-z0-9]/g, '')))%100;
	 };
	 //3 13 1 9 69
	 //1 1995 Mon Jan 09 2023 17:38:41 GMT+0100 (Mitteleuropäische Normalzeit) 3 13 1 9 69 3
	 var datan=data.toString().replace(/[^0-9]/g, '');
	 return this.qS(datan);
	 //return this.qS(parseInt( ))%100;
	  var t = data.toString().replace(/[^0-9]/g, '');
	  var l = Math.floor(t.length/4);
	  var t1 = t.substring(0, l);
	  var t2 = t.substring(l,2*l);
	  var t3 = t.substring(2*l, 3*l);
	  var t4 = t.substring(3*l,t.length);	  
	  var ret = "";
	  if (t1.length >0) {ret = ret + this.qS(parseInt(t1))%100};
	  if (t2.length >0) {ret = ret + this.qS(parseInt(t2))%100};
	  if (t3.length >0) {ret = ret + this.qS(parseInt(t3))%100};
	  if (t4.length >0) {ret = ret + this.qS(parseInt(t4))%100};
	  if (ret.length > 0) {
		return this.qS(parseInt(ret))%100;
	  }
	  else {
		return "";
	  }
  }
  
  pruefNummer(dta) {
	  var z = this.querSumme(dta);
	  var r = 2*((z%10===5)?1:0);
	  r+= (z%2===0)?1:0;
	  r+=2* ((z%10===4)?1:0);
	  r+=z;
	  return r;
  }
  
  secHash(dta) {
	  this.data = dta;
	  return this.recalculateHash();
}

 secHashDec(dta) {
	  this.data = dta;
	  return hexToDec(this.recalculateHash());
}
  
  iban(dtav) {
	var dta = String(dtav).toUpperCase().replace(/[^A-Z0-9]/g, '')
    if(dta.toString().startsWith('DE')) {
		if (dta.toString().length === 22) {
			return (98-this.isValidIBANNumber(dta));
		}
	}
	return "";
}

mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}


 mod97full(string) {
    var checksum = parseInt(string.replace(/[^0-9]/g,''), 10) % 97;
    return checksum;
}

isValidIBANNumber(input) {
        var CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
        };
        var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''),
                code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
                digits;
        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return "kein IBAN";
        }
        // rearrange country code and check digits, and convert chars to ints
        digits = (code[3] + code[1] + "00").replace(/[A-Z]/g, function (letter) {
                                                                 return letter.charCodeAt(0) - 55;
                                                                 }
                 );
        // final check
		return this.mod97(digits);
    }

secToBase58(strng) {
    var strhash = sha256(strng ).toString();
	return this.convertBase(strhash,16);
}

toBase58(strng) {
	if (typeof strng !== 'string' || strng.length == 0 ) { 
		return ""; 
	}
	strng = String(strng).replace(/[^A-Za-z0-9]/g, '').toLowerCase();
	if (strng.startsWith('0x'))
		if (strng.length===2) {
			return ""
		}
		else {
			strng = strng.substring(2,strng.length);
			return this.convertBase(strng,16);
		}
	var dta = String(strng).replace(/[^0-9]/g, '');	
	return this.convertBase(dta,10);


//0x16 58^2=3364 | 61: 5*58=290 0x122 | A1: 9*58=522 0x20a | 56*58=3248 57*58=3306 57*58 + 54 =3363
// 0x1ceeb25aa9931466dfda599653d827c8fe70e9a20278ebe5744206ed165f54e7
//76949280022739288613800702199389566746553352754411619296370143719059980932905
//var eing = strng.startsWith('0x') && strng.length >2?strng.substring(2, strng.length):decToHex(strng);//this.secHash(strng);
//Thu Oct 06 2022 14:58:33 GMT+02 00 (Mitteleuropäische Sommerzeit) 9 3 10 13 73 9 7 10 17 97 9 11 10 13 73 9 13 10 17 97 9 19 10 21 81
// 38 + 44 + 59 + 61 = 202
//39
//1 7875 3 Mon Jan 09 2023 15:13:46 GMT+0100 (Mitteleuropäische Normalzeit) 3 13 1 9 69
  }
}