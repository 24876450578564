import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  
} from "react-router-dom";
import { BreakpointProvider } from "react-socks";

import React, { useState } from "react";
import Header		from "./components/Header";
import Main 		from"./components/Main";
import Nav 			from "./components/Nav";
import EggCoins 	from "./Pages/EggCoins";
import EggBeispiel	from "./Pages/EggBeispiel";
import PrfSig		from "./Pages/PrfSig";
import CreSig		from "./Pages/CreSig";
//opt import Vorstellung	from "./Pages/Vorstellung";
import EggTraining	from "./Pages/EggTraining";
import EggHacking	from "./Pages/EggHacking";
import Hashs		from "./Pages/Hashs";
import Hashsmod		from "./Pages/Hashsmod";
import ECDSA		from "./Pages/ECDSA"
import Zahlensys	from "./Pages/Zahlensys";
import CryptoFiat	from "./Pages/CryptoFiat";
import Spielregeln	from "./Pages/Spielregeln";
import Mod100		from "./Pages/Mod100";
import Signatur		from "./Pages/Signatur";
import DSVGO		from "./Pages/DSVGO";

import ScrollToTop from "./helpers/ScrollToTop.js";

export const activeStyle = {
  color: "#2955f3",
  filter: "drop-shadow(0 0 2px #0a3cef22)",
};
// wrap react socks
function App() {

	const [showNav, setShowNav] = useState(true);//weli https://stackoverflow.com/questions/71444637

	return (
<BreakpointProvider>
	<div className="App">
    <Router>
	<ScrollToTop />
      <Header />
  
	  <Nav>
        <ul>
          <li>
            <NavLink
              exact='true'
              to='/'
              style={({ isActive }) => (isActive ? activeStyle : {})}>
              Home&nbsp;
            </NavLink>
          </li>
		  { showNav &&    
		  <li>
            <NavLink
              exact='true'
              to='/EggHacking'
              style={({ isActive }) => (isActive ? activeStyle : {})}>
              Hacking&nbsp;
            </NavLink>
          </li>
	}
		  { showNav &&   	
		  <li>
            <NavLink
              exact='true'
              to='/Hashs'
              style={({ isActive }) => (isActive ? activeStyle : {})}>
              Hash's&nbsp;
            </NavLink>
          </li>
	}
		  { showNav &&  	
		  <li>
            <NavLink
              exact='true'
              to='/Signatur'
              style={({ isActive }) => (isActive ? activeStyle : {})}>
              Signatur&nbsp;
            </NavLink>
          </li>
	}
          <li>
            <NavLink
              exact='true'
              to='/dsvgo'
              style={({ isActive }) => (isActive ? activeStyle : {})}>
              &nbsp;DSVGO
            </NavLink>
          </li>
	  	</ul>
	  </Nav>

      <Main>
        <Routes>
		   	<Route index path='/'		element={<EggCoins funcNav={setShowNav}/>}></Route>
			<Route path='/eggbeispiel'	element={<EggBeispiel funcNav={setShowNav}/>}></Route>
			<Route path='/prfsig'		element={<PrfSig funcNav={setShowNav}/>}></Route>
			<Route path='/cresig'		element={<CreSig funcNav={setShowNav}/>}></Route>
			<Route path='/egghacking'	element={<EggHacking funcNav={setShowNav}/>}></Route>
			<Route path='/eggtraining'		element={<EggTraining funcNav={setShowNav}/>}></Route>
			<Route path='/hashs'		element={<Hashs funcNav={setShowNav}/>}></Route>
			<Route path='/signatur'		element={<Signatur funcNav={setShowNav}/>}></Route>
			<Route path='/dsvgo'      	element={<DSVGO />}></Route>			
			<Route path='/hashsmod'		element={<Hashsmod funcNav={setShowNav}/>}></Route>
			<Route path='/mod100'		element={<Mod100 funcNav={setShowNav}/>}></Route>
			<Route path='/cryptofiat'	element={<CryptoFiat funcNav={setShowNav}/>}></Route>
			<Route path='/zahlensys'	element={<Zahlensys funcNav={setShowNav}/>}></Route>
			<Route path='/ecdsa'		element={<ECDSA funcNav={setShowNav}/>}></Route>
			<Route path='/spielregeln'	element={<Spielregeln funcNav={setShowNav}/>}></Route>
        </Routes>
      </Main>
    </Router>
	</div>
</BreakpointProvider>	
  );
}

export default App;
